import {Component, DestroyRef, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
  ButtonModule,
  FormBlockComponent,
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent
} from '@adnova/jf-ng-components';
import {NgIf} from '@angular/common';
import {ProduktFormControls} from './produkt-form-controls.interface';
import {DeepPartial} from '../../../../types/deep-partial';
import {
  BerechnungsregelFestbetragDTO,
  BerechnungsregelKeineDTO,
  BerechnungsregelMengeMalEinzelpreisDTO,
  ProduktDTO,
} from '../../../../openapi/fakturierung-openapi';
import {MengeneinheitExtended} from '../../../../interfaces/mengeneinheit-extended.interface';
import {UmsatzsteuerschluesselExtended} from '../../../../interfaces/umsatzsteuerschluessel-extended.interface';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {debounceTime} from 'rxjs';
import Decimal from 'decimal.js';


@Component({
  selector: 'app-produkt-form',
  standalone: true,
  imports: [
    ButtonModule,
    FormFieldNumberComponent,
    ReactiveFormsModule,
    FormFieldSelectComponent,
    FormFieldTextComponent,
    NgIf,
    FormBlockComponent,
  ],
  templateUrl: './produkt-form.component.html',
  styleUrls: ['./produkt-form.component.scss']
})
export class ProduktFormComponent implements OnChanges {

  private _destroyRef = inject(DestroyRef);

  private _produkt?: DeepPartial<ProduktDTO>;
  private _berechnungsarten: string[] = [];
  private _berechnungsartenOptions: OptionComponent[] = [];
  private _einheiten: MengeneinheitExtended[] = [];
  private _einheitenOptions: OptionComponent[] = [];
  private _umsatzsteuersaetze: UmsatzsteuerschluesselExtended[] = [];
  private _umsatzsteuersaetzeOptions: OptionComponent[] = [];
  private _formControls?: FormGroup<ProduktFormControls>;

  @Output()
  produktChanged = new EventEmitter<DeepPartial<ProduktDTO>>();

  @Input()
  get produkt(): DeepPartial<ProduktDTO> | undefined {
    return this._produkt;
  }

  set produkt(value: DeepPartial<ProduktDTO> | undefined) {
    this._produkt = value;
  }

  @Input()
  get berechnungsarten(): string[] {
    return this._berechnungsarten;
  }

  set berechnungsarten(value: string[]) {
    this._berechnungsarten = value;
  }

  get berechnungsartenOptions(): OptionComponent[] {
    return this._berechnungsartenOptions;
  }

  set berechnungsartenOptions(value: OptionComponent[]) {
    this._berechnungsartenOptions = value;
  }

  @Input()
  get einheiten(): MengeneinheitExtended[] {
    return this._einheiten;
  }

  set einheiten(value: MengeneinheitExtended[]) {
    this._einheiten = value;
  }

  get einheitenOptions(): OptionComponent[] {
    return this._einheitenOptions.sort((a, b) => a.label!.localeCompare(b.label!));
  }

  set einheitenOptions(value: OptionComponent[]) {
    this._einheitenOptions = value;
  }

  @Input()
  get umsatzsteuersaetze(): UmsatzsteuerschluesselExtended[] {
    return this._umsatzsteuersaetze;
  }

  set umsatzsteuersaetze(value: UmsatzsteuerschluesselExtended[]) {
    this._umsatzsteuersaetze = value;
  }

  get umsatzsteuersaetzeOptions(): OptionComponent[] {
    return this._umsatzsteuersaetzeOptions;
  }

  set umsatzsteuersaetzeOptions(value: OptionComponent[]) {
    this._umsatzsteuersaetzeOptions = value;
  }

  @Input()
  get formControls(): FormGroup<ProduktFormControls> | undefined {
    return this._formControls;
  }

  set formControls(value: FormGroup<ProduktFormControls> | undefined) {
    this._formControls = value;
  }

  /*
   * INFO: Wenn durch Validators.min(10000) ein Fehler auftritt, soll ein entsprechender Text ausgegeben werden.
   */
  get produktnummerValidatorMessage(): string {
    const control = this.formControls?.controls.produktnummer;
    const errors = control?.errors;
    if (errors?.min) {
      return 'Produktnummer muss mindestens fünfstellig sein.';
    }
    return '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.einheiten) {
      this.einheitenOptions = this.generateEinheitenOptions();
    }

    if (changes.berechnungsarten) {
      this.berechnungsartenOptions = this.generateBerechnungsartenOptions();
    }

    if (changes.umsatzsteuersaetze) {
      this.umsatzsteuersaetzeOptions = this.generateUmsatzsteuersaetzeOptions();
    }

    if (changes.produkt || changes.formControls) {
      this.updateProduktdaten();
    }

    if (changes.formControls) {
      this.emitOnFormChanges();
    }
  }

  private updateProduktdaten(): void {

    this.berechnungsartenOptions = this.generateBerechnungsartenOptions();
    this.einheitenOptions = this.generateEinheitenOptions();
    this.umsatzsteuersaetzeOptions = this.generateUmsatzsteuersaetzeOptions();

    const produkt = this.produkt;
    const berechungsregelTyp = produkt?.berechnungsregel?.typ;

    if (produkt?.nummer !== undefined) {
      this.formControls?.controls.produktnummer.setValue(Decimal(produkt.nummer));
    }

    if (produkt?.bezeichnung) {
      this.formControls?.controls.produktBezeichnung.setValue(produkt.bezeichnung);
    }

    if (produkt?.beschreibung) {
      this.formControls?.controls.produktbeschreibung.setValue(produkt.beschreibung);
    }

    // INFO: Ermittlung des Betrags.
    let betrag: number | undefined;
    switch (berechungsregelTyp) {
      case 'BerechnungsregelMengeMalEinzelpreis':
        const berechnungsregelMengeMalEinzelpreisDTO = produkt?.berechnungsregel as BerechnungsregelMengeMalEinzelpreisDTO;
        betrag = berechnungsregelMengeMalEinzelpreisDTO.betrag;
        break;
      case 'BerechnungsregelFestbetrag':
        const berechnungsregelFestbetragDTO = produkt?.berechnungsregel as BerechnungsregelFestbetragDTO;
        betrag = berechnungsregelFestbetragDTO.betrag;
        break;
      case 'BerechnungsregelKeine':
        const berechnungsregelKeineDTO = produkt?.berechnungsregel as BerechnungsregelKeineDTO;
        break;
    }

    if (betrag) {
      this.formControls?.controls.betrag.setValue(Decimal(betrag));
    }
  }

  private generateBerechnungsartenOptions(): OptionComponent[] {
    const berechungsregelTyp = this.produkt?.berechnungsregel?.typ;
    let bruttoBerechnung = false;

    switch (berechungsregelTyp) {
      case 'BerechnungsregelMengeMalEinzelpreis':
        bruttoBerechnung = (this.produkt?.berechnungsregel as BerechnungsregelMengeMalEinzelpreisDTO).bruttoberechnung;
        break;
      case 'BerechnungsregelFestbetrag':
        bruttoBerechnung = (this.produkt?.berechnungsregel as BerechnungsregelFestbetragDTO).bruttoberechnung;
        break;
      case 'BerechnungsregelKeine':
        bruttoBerechnung = false;
        break;
    }

    const berechnung = bruttoBerechnung ? 'brutto' : 'netto';

    return this.berechnungsarten.map(berechnungsartTyp => {
      const option = new OptionComponent();
      option.id = berechnungsartTyp.toLowerCase();
      option.label = berechnungsartTyp;
      option.isSelected = berechnung === berechnungsartTyp.toLowerCase();
      return option;
    });
  }

  private generateEinheitenOptions(): OptionComponent[] {
    const berechungsregelTyp = this.produkt?.berechnungsregel?.typ;
    let selectedEinheit: string | undefined;
    switch (berechungsregelTyp) {
      case 'BerechnungsregelMengeMalEinzelpreis':
        selectedEinheit = (this.produkt?.berechnungsregel as BerechnungsregelMengeMalEinzelpreisDTO).mengeneinheitNummer.toString();
        break;
      case 'BerechnungsregelFestbetrag':
        selectedEinheit = undefined;
        break;
      case 'BerechnungsregelKeine':
        selectedEinheit = undefined;
        break;
    }

    return this.einheiten.map(einheit => {
      const option = new OptionComponent();
      option.id = einheit.nummer.toString();
      option.label = einheit.bezeichnung;
      option.isSelected = !!selectedEinheit && selectedEinheit === einheit.nummer.toString();
      return option;
    });
  }

  private generateUmsatzsteuersaetzeOptions(): OptionComponent[] {
    const berechungsregelTyp = this.produkt?.berechnungsregel?.typ;
    let selectedUmsatzsteuersatz: string | undefined;
    switch (berechungsregelTyp) {
      case 'BerechnungsregelMengeMalEinzelpreis':
        selectedUmsatzsteuersatz = (this.produkt?.berechnungsregel as BerechnungsregelMengeMalEinzelpreisDTO).umsatzsteuerschluesselNummer.toString();
        break;
      case 'BerechnungsregelFestbetrag':
        selectedUmsatzsteuersatz = undefined;
        break;
      case 'BerechnungsregelKeine':
        selectedUmsatzsteuersatz = undefined;
        break;
    }
    return this.umsatzsteuersaetze.map(umsatzsteuersatz => {
      const option = new OptionComponent();
      option.id = umsatzsteuersatz.nummer.toString();
      option.label = umsatzsteuersatz.bezeichnung;
      option.isSelected = !!selectedUmsatzsteuersatz && selectedUmsatzsteuersatz === umsatzsteuersatz.nummer.toString();
      return option;
    });
  }

  private emitOnFormChanges(): void {
    this.formControls?.valueChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
      debounceTime(100),
    ).subscribe(value => {

      const berechnungsregelTyp = value.berechnungsart?.selectedOptionValueIds?.at(0) || 'BerechnungsregelMengeMalEinzelpreis';
      const mengeneinheitString = value.einheit?.selectedOptionValueIds?.at(0);
      const mengeneinheitNummer = mengeneinheitString ? parseInt(mengeneinheitString, 10) : undefined;
      const umsatzsteuerschluesselString = value.ustProzentsatz?.selectedOptionValueIds?.at(0);
      const umsatzsteuerschluesselNummer = umsatzsteuerschluesselString ? parseInt(umsatzsteuerschluesselString, 10) : undefined;

      let berechnungsregel = {
        typ: berechnungsregelTyp,
      } as BerechnungsregelFestbetragDTO | BerechnungsregelKeineDTO | BerechnungsregelMengeMalEinzelpreisDTO;

      switch (berechnungsregelTyp) {
        case 'BerechnungsregelMengeMalEinzelpreis':
          berechnungsregel = {
            ...berechnungsregel,
            mengeneinheitNummer,
            umsatzsteuerschluesselNummer,
            betrag: value.betrag?.toString().replace('.', '').replace(',', '.') || 0,
            bruttoberechnung: value.berechnungsart?.selectedOptionValueIds?.at(0) === 'brutto',
            typ: 'BerechnungsregelMengeMalEinzelpreis',
          } as BerechnungsregelMengeMalEinzelpreisDTO;
          break;
        case 'BerechnungsregelFestbetrag':
          berechnungsregel = {
            ...berechnungsregel,
            betrag: value.betrag?.toString().replace('.', '').replace(',', '.') || 0,
            bruttoberechnung: value.berechnungsart?.selectedOptionValueIds?.at(0) === 'brutto',
            umsatzsteuerschluesselNummer: value.ustProzentsatz?.selectedOptionValueIds?.at(0) || 0,
            typ: 'BerechnungsregelFestbetrag',
          } as BerechnungsregelFestbetragDTO;
          break;
        case 'BerechnungsregelKeine':
          berechnungsregel = {
            ...berechnungsregel,
            typ: 'BerechnungsregelKeine',
          } as BerechnungsregelKeineDTO;
          break;
      }

      this.produkt = {
        ...this.produkt,
        berechnungsregel,
        nummer: value.produktnummer?.toNumber() || undefined,
        beschreibung: value.produktbeschreibung || undefined,
        bezeichnung: value.produktBezeichnung || undefined,
      };
      this.produktChanged.emit(this.produkt);
    });
  }

}
