import {createEntityAdapter, EntityAdapter} from '@ngrx/entity';
import {EinstellungenResponseDTO} from '../../openapi/fakturierung-openapi';


/*
 * INFO: Es wird die Betrieb-ID als ID verwendet und nicht die ID des Einstellungen-Objekts,
 * da es nur ein Einstellungen-Objekt pro Betrieb gibt. Sollte sich während der Laufzeit die ID
 * des Einstellungen-Objekts ändern, wird somit sichergestellt, dass das Einstellungen-Objekt komplett
 * ersetzt wird, anstatt ein weiteres Objekt mit neuer ID der Liste hinzuzufügen.
 */
export const einstellungenEntitiesAdapter: EntityAdapter<EinstellungenResponseDTO> = createEntityAdapter<EinstellungenResponseDTO>({
  selectId: (einstellungen: EinstellungenResponseDTO) => einstellungen.betriebId,
});
