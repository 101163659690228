import {Injectable} from '@angular/core';
import {KundeKontaktdaten} from '../interfaces/kunde-kontaktdaten.interface';
import {
  GeschaeftskundendatenDTO,
  KundeDTO,
  PrivatkundendatenDTO
} from '../openapi/fakturierung-openapi';


@Injectable({
  providedIn: 'root'
})
export class KundeKontaktdatenUtilService {

  public getKundeKontaktdaten(kundeDto: KundeDTO): KundeKontaktdaten {
    if (kundeDto?.kundendaten.typ === 'Privatkundendaten') {
      const privatekundenDatenDto = (kundeDto.kundendaten as PrivatkundendatenDTO);
      return {
        bezeichnung: privatekundenDatenDto.vorname + ' ' + privatekundenDatenDto.nachname,
        strasseHausnummer: kundeDto.adresse.strasseHausnummer,
        postleitzahl: kundeDto.adresse.postleitzahl,
        ort: kundeDto.adresse.ort,
        land: kundeDto.adresse.land.isoAlpha2,
        titel: privatekundenDatenDto.titel,
        anrede: privatekundenDatenDto.anrede,
        vorname: privatekundenDatenDto.vorname,
        nachname: privatekundenDatenDto.nachname,
      };
    } else {
      const geschaeftskundendatenDto = (kundeDto.kundendaten as GeschaeftskundendatenDTO);
      return {
        bezeichnung: geschaeftskundendatenDto.firmenbezeichnung,
        strasseHausnummer: kundeDto.adresse.strasseHausnummer,
        postleitzahl: kundeDto.adresse.postleitzahl,
        ort: kundeDto.adresse.ort,
        land: kundeDto.adresse.land.isoAlpha2,
        titel: geschaeftskundendatenDto.ansprechpartner?.titel,
        anrede: geschaeftskundendatenDto.ansprechpartner?.anrede,
        vorname: geschaeftskundendatenDto.ansprechpartner?.vorname,
        nachname: geschaeftskundendatenDto.ansprechpartner?.nachname,
      };
    }
  }
}
