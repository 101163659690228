import {createSelector, MemoizedSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {BelegDTO} from '../../openapi/fakturierung-openapi';
import {fakturierungsbelegEntitiesAdapter} from '../adapters/fakturierungsbeleg-entities.adapter';
import {identity} from 'rxjs';


export class FakturierungsbelegEntitiesSelectors {

  private static adapterSelectors = fakturierungsbelegEntitiesAdapter.getSelectors();

  private static select = {
    belegEntities: (state: AppState) => state.fakturierungsbelegEntities,
    totalElements: (state: AppState) => state.fakturierungsbelegEntities.totalElements,
    createSaveActionSuccessful: (state: AppState) => state.fakturierungsbelegEntities.createSaveActionSuccessful,
    deleteActionSuccessful: (state: AppState) => state.fakturierungsbelegEntities.deleteActionSuccessful,
    createIsLoading: (state: AppState) => state.fakturierungsbelegEntities.createIsLoading,
    finalizeIsLoading: (state: AppState) => state.fakturierungsbelegEntities.finalizeIsLoading,
  };

  public static all = createSelector(
    this.select.belegEntities,
    this.adapterSelectors.selectAll,
  );

  public static belegById = (belegId: string) => createSelector(
    this.all,
    belegList => belegList.find(beleg => beleg.id === belegId),
  );

  public static belegeByIds = (belegIds: string[]): MemoizedSelector<AppState, BelegDTO[]> => createSelector(
    this.all,
    belegDtos => {
      const belegMap = new Map(belegDtos.map(belegDto => [belegDto.id, belegDto]));
      return belegIds
        .map(id => belegMap.get(id))
        .filter((belegDto): belegDto is BelegDTO => !!belegDto);
    }
  );

  public static belegeByBetriebId = (betriebId: string) => createSelector(
    this.all,
    belegList => belegList.filter(beleg => beleg && beleg.betriebId === betriebId),
  );

  public static totalElements = createSelector(
    this.select.totalElements,
    identity,
  );

  public static createSaveActionSuccessful = createSelector(
    this.select.createSaveActionSuccessful,
    identity,
  );

  public static deleteActionSuccessful = createSelector(
    this.select.deleteActionSuccessful,
    identity,
  );

  public static createIsLoading = createSelector(
    this.select.createIsLoading,
    identity,
  );

  public static finalizeIsLoading = createSelector(
    this.select.finalizeIsLoading,
    identity,
  );

  public static problem = (belegId: string) => createSelector(
    this.all,
    belegList => belegList.find(beleg => beleg.id === belegId)?.problem,
  );

}
