import {inject, Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {from, take, withLatestFrom} from 'rxjs';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {InhaberEntitiesActions} from '@adnova/jf-ng-components';
import {FakturierungsbelegEntitiesActions} from '../store/actions/fakturierungsbeleg-entities.actions';
import {FakturierungsbelegFormSelectors} from '../store/selectors/fakturierungsbeleg-form.selectors';
import {EinstellungenEntitiesSelectors} from '../store/selectors/einstellungen-entities.selectors';
import {EinstellungenEntitiesActions} from '../store/actions/einstellungen-entities.actions';


export interface ExampleDataV1 {
  readonly exampleId: string;
}

export interface PopupOpenDataV1 {
  readonly betriebId: string;
}

export interface UpdatedBetriebDataV1 {
  readonly betriebId: string;
}

export interface FakturierungsbelegFestgeschriebenDataV1 {
  readonly betriebId: string;
  readonly fakturierungsbelegId: string;
}

export interface FakturierungsbelegStorniertDataV1 {
  readonly betriebId: string;
  readonly fakturierungsbelegId: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  private _logger = inject(NGXLogger);
  private _portalMessageBroker = inject(PortalMessageBroker);
  private _store = inject(Store<AppState>);

  private readonly _exampleV1 = 'de.just-farming:example:example';

  private readonly _popupOpenV1 = 'de.just-farming:fakturierung:popup.edit-betriebsinformationen';

  private readonly _betriebUpdatedIdV1 = 'de.just-farming:betriebsinformationen:betrieb.updated';

  private readonly _fakturierungsbelegFestgeschriebenIdV1 = 'de.just-farming.fakturierung:fakturierungsbeleg.festgeschrieben';

  private readonly _fakturierungsbelegStorniertIdV1 = 'de.just-farming.fakturierung:fakturierungsbeleg.storniert';

  constructor() {
    if (this.isRunningInPortal()) {
      this._portalMessageBroker.registerIntentCallback(this._exampleV1, '1', (data => {
        this.handleExampleV1(data);
      }));

      this._portalMessageBroker.registerIntentCallback(this._betriebUpdatedIdV1, '1', (data => {
        this.handleBetriebUpdated(data);
      }));

      this._portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  /**
   * Prüft, ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    return this._portalMessageBroker.isRunningInPortal();
  }

  public doExampleV1(
    data: ExampleDataV1,
  ): void {
    this.doEmit(this._exampleV1, '1', data);
  }

  handleExampleV1(
    data: ExampleDataV1
  ): void {
    // TODO impl
  }

  /**
   * Öffnet das Popup zum Bearbeiten der Betriebsinformationen aus der Fakturierung.
   *
   * @param data
   */
  public doPopupOpenV1(
    data: PopupOpenDataV1,
  ): void {
    this.doEmit(this._popupOpenV1, '1', data);
  }

  /**
   * Löst das Aktualisieren der Daten des Betriebs aus.
   *
   * @param data
   */
  handleBetriebUpdated(
    data: UpdatedBetriebDataV1
  ): void {
    const betriebId = data.betriebId;
    this._logger.debug('betrieb updated intent received for betriebId:', betriebId);

    // INFO: Die Daten der Betriebe in der Betriebsauswahl müssen aktualisiert werden.
    this._store.dispatch(InhaberEntitiesActions.loadInhabers());

    /**
     * INFO:
     *  Die Einstellungen des Betriebs müssen ebenfalls neu geladen werden, da auch diese Daten innerhalb des Intents
     * aktualisiert werden können.
     */
    this._store.dispatch(EinstellungenEntitiesActions.getEinstellungenDefault({betriebId}));

    /*
     * INFO:
     * Befindet sich parallel ein Beleg in Bearbeitung, so müssen die Beleg-Daten in der UI aktualisiert werden.
     * Dies kann erreicht werden, wenn der Beleg einmalig gespeichert wird.
     * Anschließend erhalten wir vom Fakturierung-Service die neuen Daten, um diese darzustellen.
     */
    this._store.select(FakturierungsbelegFormSelectors.fakturierungsbeleg).pipe(
      withLatestFrom(this._store.select(EinstellungenEntitiesSelectors.zahlungszielTageByBetrieb(betriebId))),
      take(1),
    ).subscribe(([beleg, zahlungszielTage]) => {
      // INFO: Wenn kein Beleg in Bearbeitung ist, dann wird nichts aktualisiert.
      if (!beleg.id) return;

      this._store.dispatch(FakturierungsbelegEntitiesActions.updateFakturierungsbeleg({
        betriebId,
        belegId: beleg.id,
        updateBelegRequestDto: {
          kundeId: beleg.kundeId,
          datum: beleg.datum || '',
          leistungsdatum: beleg.leistungsdatum || '',
          vorlaufzeile: beleg.vorlaufzeile,
          zahlungszielTage,
        },
        fetchPositions: false,
      }));
    });
  }

  /**
   * Informiert andere Apps über das Festschreiben eines Belegs per Broadcast Hintergrund-Intent.
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doFakturierungsbelegFestgeschriebenV1(
    data: FakturierungsbelegFestgeschriebenDataV1,
  ): void {
    this._logger.info('fakturierung app send fakturierungsbeleg festgeschrieben intent', data);

    this.doEmit(this._fakturierungsbelegFestgeschriebenIdV1, '1', data);
  }

  /**
   * Informiert andere Apps über das Stornieren eines Belegs per Broadcast Hintergrund-Intent.
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doFakturierungsbelegStorniertV1(
    data: FakturierungsbelegStorniertDataV1,
  ): void {
    this._logger.info('fakturierung app send fakturierungsbeleg storniert intent', data);

    this.doEmit(this._fakturierungsbelegStorniertIdV1, '1', data);
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this._portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this._logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}
