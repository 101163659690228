import {createReducer, on} from '@ngrx/store';
import {CancelFakturierungsbelegDialogActions} from '../actions/cancel-fakturierungsbeleg-dialog.actions';
import {CancelFakturierungsbelegDialogState} from '../states/cancel-fakturierungsbeleg-dialog.state';


export const initialCancelFakturierungsbelegDialogState: CancelFakturierungsbelegDialogState = {
  isOpen: false,
};

export const cancelFakturierungsbelegDialogReducer = createReducer(
  initialCancelFakturierungsbelegDialogState,

  on(
    CancelFakturierungsbelegDialogActions.open,
    (state, action) => ({
      ...state,
      isOpen: true,
      belegId: action.belegId,
      betriebId: action.betriebId,
    })
  ),

  on(
    CancelFakturierungsbelegDialogActions.close,
    () => ({
      ...initialCancelFakturierungsbelegDialogState,
    })
  ),
);
