<mat-dialog-content>
  <jf-form-wrapper
    [title]="title"
    [padding]="false"
    [buttons]="formActionButtons"
  >
    <app-produkt-form
      content
      [produkt]="produktDto"
      [formControls]="formGroup"
      [einheiten]="einheiten"
      [berechnungsarten]="berechnungsarten"
      [umsatzsteuersaetze]="umsatzsteuersaetze"
      (produktChanged)="produktChanged($event)"
    ></app-produkt-form>
  </jf-form-wrapper>
</mat-dialog-content>
