import {Component, DestroyRef, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FormBlockComponent,
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent
} from '@adnova/jf-ng-components';
import {FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDividerModule} from '@angular/material/divider';
import {KundeDTO, LandDTO, PrivatkundendatenDTO} from '../../../../openapi/fakturierung-openapi';
import {KundePrivatpersonFormControls} from './kunde-privatperson-form.controls';
import {DeepPartial} from '../../../../types/deep-partial';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {debounceTime} from 'rxjs';
import Decimal from 'decimal.js';


@Component({
  selector: 'app-kunde-privatperson-form',
  standalone: true,
  imports: [
    CommonModule,
    FormFieldSelectComponent,
    FormFieldTextComponent,
    FormFieldNumberComponent,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    FormBlockComponent,
  ],
  templateUrl: './kunde-privatperson-form.component.html',
  styleUrls: ['./kunde-privatperson-form.component.scss']
})
export class KundePrivatpersonFormComponent implements OnChanges {

  private _destroyRef = inject(DestroyRef);

  private _kunde?: DeepPartial<KundeDTO>;
  private _anreden: string[] = [];
  private _anredeOptions: OptionComponent[] = [];
  private _laender: LandDTO[] = [];
  private _landOptions: OptionComponent[] = [];
  private _formControls?: FormGroup<KundePrivatpersonFormControls>;
  private _emailValidatorMessage = '';
  private _plzValidatorMessage = '';
  private _kundennummerValidatorMessage = '';
  private _ortValidatorMessage = '';

  @Output()
  kundeChanged = new EventEmitter<DeepPartial<KundeDTO>>();

  @Input()
  get kunde(): DeepPartial<KundeDTO> | undefined {
    return this._kunde;
  }

  set kunde(value: DeepPartial<KundeDTO> | undefined) {
    this._kunde = value;
  }

  get kundendaten(): DeepPartial<PrivatkundendatenDTO> | undefined {
    return this.kunde?.kundendaten;
  }

  @Input()
  get anreden(): string[] {
    return this._anreden;
  }

  set anreden(value: string[]) {
    this._anreden = value;
  }

  get anredeOptions(): OptionComponent[] {
    return this._anredeOptions;
  }

  set anredeOptions(value: OptionComponent[]) {
    this._anredeOptions = value;
  }

  @Input()
  get laender(): LandDTO[] {
    return this._laender;
  }

  set laender(value: LandDTO[]) {
    this._laender = value;
  }

  get landOptions(): OptionComponent[] {
    return this._landOptions;
  }

  set landOptions(value: OptionComponent[]) {
    this._landOptions = value;
  }

  @Input()
  get formControls(): FormGroup<KundePrivatpersonFormControls> | undefined {
    return this._formControls;
  }

  set formControls(value: FormGroup<KundePrivatpersonFormControls> | undefined) {
    this._formControls = value;
  }

  get emailValidatorMessage(): string {
    return this._emailValidatorMessage;
  }

  get plzValidatorMessage(): string {
    return this._plzValidatorMessage;
  }

  get ortValidatorMessage(): string {
    return this._ortValidatorMessage;
  }

  get kundennummerValidatorMessage(): string {
    return this._kundennummerValidatorMessage;
  }

  /*
   * INFO: Wenn durch Validators.min(10000) ein Fehler auftritt, soll ein entsprechender Text ausgegeben werden.
   */
  getKundennummerValidatorMessage(): string {
    const control = this.formControls?.controls.kundennummer;
    const errors = control?.errors;
    if (errors?.min) {
      return 'Kundennummer muss mindestens fünfstellig sein.';
    }
    return '';
  }

  getEmailValidatorMessage(): string {
    const control = this.formControls?.controls.email;
    const errors = control?.errors;
    if (errors?.email || errors?.pattern) {
      return 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
    }
    return '';
  }

  getPlzValidatorMessage(): string {
    const control = this.formControls?.controls.plz;
    const errors = control?.errors;
    if (errors?.minlength || errors?.maxlength) {
      return 'Muss fünfstellig sein';
    } else if (errors?.pattern) {
      return 'Darf nur Zahlen enthalten';
    }
    return '';
  }

  getOrtValidatorMessage(): string {
    const control = this.formControls?.controls.ort;
    const errors = control?.errors;
    if (errors?.pattern) {
      return 'Darf keine Sonderzeichen enthalten';
    }
    return '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.anreden) {
      this.anredeOptions = this.generateAnredenOptions();
    }

    if (changes.laender) {
      this.landOptions = this.generateLandOptions();
    }

    if (changes.kunde || changes.formControls) {
      this.updateKundendaten();
    }

    if (changes.formControls) {
      this.emitOnFormChanges();
    }
  }

  private updateKundendaten(): void {

    this.anredeOptions = this.generateAnredenOptions();
    this.landOptions = this.generateLandOptions();

    const kunde = this.kunde;
    const kundendaten = this.kundendaten;
    const adresse = this.kunde?.adresse;

    if (kunde?.kundennummer !== undefined) {
      this.formControls?.controls.kundennummer.setValue(Decimal(kunde.kundennummer));
    }

    if (kundendaten?.emailAdresse) {
      this.formControls?.controls.email.setValue(kundendaten.emailAdresse);
    }

    if (kundendaten?.titel) {
      this.formControls?.controls.titel.setValue(kundendaten.titel);
    }

    if (kundendaten?.vorname) {
      this.formControls?.controls.vorname.setValue(kundendaten.vorname);
    }

    if (kundendaten?.nachname) {
      this.formControls?.controls.nachname.setValue(kundendaten.nachname);
    }

    if (adresse?.strasseHausnummer) {
      this.formControls?.controls.strasseHausnummer.setValue(adresse.strasseHausnummer);
    }

    if (adresse?.postleitzahl) {
      this.formControls?.controls.plz.setValue(adresse.postleitzahl);
    }

    if (adresse?.ort) {
      this.formControls?.controls.ort.setValue(adresse.ort);
    }

  }

  private generateAnredenOptions(): OptionComponent[] {
    const selectedAnrede = this.kundendaten?.anrede;
    return this.anreden.map(anrede => {
      const option = new OptionComponent();
      option.id = anrede;
      option.label = anrede;
      option.isSelected = !!selectedAnrede && selectedAnrede === anrede;
      return option;
    });
  }


  private generateLandOptions(): OptionComponent[] {
    const selectedLand = this.kunde?.adresse?.land;
    return this.laender.map(land => {
      const option = new OptionComponent();
      option.id = land.isoAlpha2;
      option.label = land.bezeichnung;
      option.isSelected = (!!selectedLand && selectedLand.isoAlpha2 === land.isoAlpha2) || option.label === 'Deutschland';
      return option;
    });
  }

  private emitOnFormChanges() {
    this.formControls?.valueChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
      debounceTime(100),
    ).subscribe(value => {
      this.kunde = {
        ...this.kunde,
        kundennummer: value.kundennummer === null ? undefined : value.kundennummer?.toNumber(),
        adresse: {
          ...this.kunde?.adresse,
          ort: value.ort || undefined,
          postleitzahl: value.plz || undefined,
          land: this._laender.find(land => land.isoAlpha2 === value.landPrivat?.selectedOptionValueIds?.at(0)),
          strasseHausnummer: value.strasseHausnummer || undefined,
        },
        kundendaten: {
          ...this.kundendaten,
          anrede: value.anrede?.selectedOptionValueIds?.at(0) === '-' ? undefined : value.anrede?.selectedOptionValueIds?.at(0),
          nachname: value.nachname || undefined,
          titel: value.titel || undefined,
          vorname: value.vorname || undefined,
          emailAdresse: value.email || undefined,
        } as PrivatkundendatenDTO,
      };
      this.setValidatorMessages();
      this.kundeChanged.emit(this.kunde);
    });
  }

  private setValidatorMessages() {
    this._emailValidatorMessage = this.getEmailValidatorMessage();
    this._plzValidatorMessage = this.getPlzValidatorMessage();
    this._kundennummerValidatorMessage = this.getKundennummerValidatorMessage();
    this._ortValidatorMessage = this.getOrtValidatorMessage();
  }

}
