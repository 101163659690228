<ng-container *ngIf="formControls && anredeOptions && landOptions">
  <jf-form-block>
    <jf-form-field-text
      class="form-field-firma"
      [formControl]="formControls.controls.firma"
      [label]="'Firma'"
    ></jf-form-field-text>

    <jf-form-field-number
      class="form-field-kundennummer"
      [message]="kundennummerValidatorMessage"
      [formControl]="formControls.controls.kundennummer"
      [label]="'Kundennummer'"
      [decimalPlaces]="0"
      [allowNegative]="false"
    ></jf-form-field-number>

    <jf-form-field-text
      class="form-field-strasse"
      [formControl]="formControls.controls.strasseHausnummer"
      [label]="'Straße + Hausnummer'"
    ></jf-form-field-text>

    <jf-form-field-text
      class="form-field-plz"
      [maxlength]="5"
      [formControl]="formControls.controls.plz"
      [label]="'PLZ'"
      [message]="plzValidatorMessage"
    ></jf-form-field-text>

    <jf-form-field-text
      class="form-field-ort"
      [formControl]="formControls.controls.ort"
      [message]="ortValidatorMessage"
      [label]="'Ort'"
    ></jf-form-field-text>

    <jf-form-field-select
      class="form-field-land"
      [formControl]="formControls.controls.landGeschaeft"
      [options]="landOptions"
      [label]="'Land'"
    ></jf-form-field-select>

    <jf-form-field-text
      class="form-field-ustid"
      [formControl]="formControls.controls.ustId"
      [message]="ustIdValidatorMessage"
      [label]="'USt-ID'">
    </jf-form-field-text>

    <jf-form-field-text
      class="form-field-email form-field-full-width"
      [formControl]="formControls.controls.email"
      [label]="'E-Mail-Adresse'"
      [message]="emailValidatorMessage"
    ></jf-form-field-text>
  </jf-form-block>

  <jf-form-block
    *ngIf="formControls && anredeOptions && landOptions"
    title="Ansprechpartner (optionale Angaben)"
  >
    <jf-form-field-select
      class="form-field-anrede"
      [label]="'Anrede'"
      [formControl]="formControls.controls.ansprechpartnerAnrede"
      [options]="anredeOptions"
    ></jf-form-field-select>

    <jf-form-field-text
      class="form-field-titel"
      [label]="'Titel'"
      [formControl]="formControls.controls.ansprechpartnerTitel"
    ></jf-form-field-text>

    <jf-form-field-text
      class="form-field-vorname"
      [label]="'Vorname'"
      [formControl]="formControls.controls.ansprechpartnerVorname"
    ></jf-form-field-text>

    <jf-form-field-text
      class="form-field-nachname"
      [formControl]="formControls.controls.ansprechpartnerNachname"
      [label]="'Nachname'"
    ></jf-form-field-text>
  </jf-form-block>
</ng-container>
