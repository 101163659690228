<div class="container">
  <div class="header">
    <h1>Dein Logo</h1>
    <jf-button
      [variant]="'ghost'"
      [size]="'small'"
      [ariaLabel]="'Dialog schließen'"
      (doClick)="closeDialog()">
      <jf-icon [name]="'close'" [size]=28 [strokeWidth]="1"></jf-icon>
    </jf-button>
  </div>

  <main>
    <p> Füge dein Firmenlogo hinzu, so kann jeder schnell erkennen, dass dieses Dokument von dir stammt. </p>
    <ul>
      <li> Zulässige Datei-Formate: JPG, PNG</li>
      <li> Optimale Größe: 800 Pixel</li>
      <li> Maximale Dateigröße: 5 MB</li>
    </ul>

    <button class="drag-and-drop-logo-area"
            [ngClass]="{'successful-upload': isLogoUploadedInTheDialog}"
            (dragover)="onDragOver($event)"
            (drop)="onDrop($event)"
            (dragleave)="onDragLeave($event)"
            (click)="handleClickOfLogoViewer()">
      <p *ngIf="!uploadedLogoInDialog"> Bild hier ablegen </p>
      <img *ngIf="uploadedLogoInDialog" [src]="uploadedLogoInDialog" alt="Uploaded Logo">
    </button>

  </main>

  <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" accept="image/png, image/jpeg">

  <div class="error-message" *ngIf="errorMessageToDisplay" role="alert">
    <jf-icon class="warning-icon" [size]="28" [name]="'warning'" [iconColor]="'#FF4747'"></jf-icon>
    <p> {{ errorMessageToDisplay }} </p>
  </div>

  <div class="buttons-wrapper">
    <jf-button
      [variant]="'primary'"
      [iconPosition]="'right'"
      (doClick)="isLogoUploadedInTheDialog ? saveLogoOrDeleteItInBackend() : fileInput.click()">
      {{ isLogoUploadedInTheDialog ? 'Speichern' : 'Logo hochladen' }}
      <jf-icon *ngIf="!isLogoUploadedInTheDialog" [name]="'upload'" [size]="26" [strokeWidth]="1"></jf-icon>
    </jf-button>
    <jf-button
      *ngIf="isLogoUploadedInTheDialog || isDialogTouched"
      [variant]="'outline'"
      (click)="isLogoUploadedInTheDialog ? removeLogoInTheDialog() : saveLogoOrDeleteItInBackend()">
      {{ isLogoUploadedInTheDialog ? 'Logo entfernen' : 'Speichern' }}
    </jf-button>
  </div>
</div>


