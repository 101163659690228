import {BelegDTO} from '../openapi/fakturierung-openapi';


export function generateDownloadFilename(beleg?: BelegDTO): string {

  let prefix: string;
  let middle: string;
  const suffix = '.pdf';

  // INFO: Überprüfen, ob der Beleg eine Rechnungskorrektur ist
  if (beleg?.originalBelegId) {
    prefix = 'Rechnungskorrektur_';

  // INFO: Überprüfen, ob der Beleg eine stornierte Originalrechnung ist
  } else if (beleg?.korrekturBelegId) {
    prefix = 'Rechnung_';

  // INFO: Überprüfen, ob der Beleg ein Entwurf ist
  } else if (beleg?.status.offen) {
    prefix = 'Rechnung_Entwurf_';

  // INFO: Andernfalls ist der Beleg fertiggestellt
  } else {
    prefix = 'Rechnung_';
  }

  // INFO: Wenn eine Rechnungsnummer vorhanden ist, wird diese zur Identifizierung verwendet.
  if (beleg?.nummer) {
    middle = beleg.nummer;

  // INFO: Andernfalls wird die Beleg-ID verwendet, sofern vorhanden.
  } else if (beleg?.id) {
    middle = beleg.id;

  // INFO: Andernfalls wird ein leerer String verwendet.
  } else {
    middle = '';
  }

  return `${prefix}${middle}${suffix}`;
}
