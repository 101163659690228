<jf-form-block
  *ngIf="formControls && einheitenOptions && berechnungsarten && umsatzsteuersaetze"
>
  <jf-form-field-text
    class="form-field-title"
    [label]="'Produkt'"
    [formControl]="formControls.controls.produktBezeichnung"
  ></jf-form-field-text>

  <jf-form-field-select
    class="form-field-einheit"
    [label]="'Einheit'"
    [formControl]="formControls.controls.einheit"
    [options]="einheitenOptions"
  ></jf-form-field-select>

  <jf-form-field-number
    class="form-field-preis"
    [label]="'Preis in €'"
    [formControl]="formControls.controls.betrag"
    [decimalPlaces]="2"
    [roundDecimals]="true"
    [thousandSeparator]="'.'"
    [decimalSeparator]="','"
    [fillDecimals]="true"
    [min]="-99999999.99"
    [max]="99999999.99"
  ></jf-form-field-number>

  <jf-form-field-select
    class="form-field-berechnungsart"
    [label]="'Preisberechnung in:'"
    [formControl]="formControls.controls.berechnungsart"
    [options]="berechnungsartenOptions"
    [message]="'Derzeit nur Netto möglich.'"
  ></jf-form-field-select>

  <jf-form-field-select
    class="form-field-ustSatz"
    [label]="'Umsatzsteuersatz'"
    [formControl]="formControls.controls.ustProzentsatz"
    [options]="umsatzsteuersaetzeOptions"
  ></jf-form-field-select>

  <jf-form-field-text
    class="form-field-beschreibung"
    [formControl]="formControls.controls.produktbeschreibung"
    [label]="'Produktbeschreibung'"
    [message]="'Produkt und Produktbeschreibung werden auf der Rechnung angezeigt'"
  ></jf-form-field-text>

  <jf-form-field-number
    class="form-field-produktnummer"
    [label]="'Produktnummer'"
    [formControl]="formControls.controls.produktnummer"
    [decimalPlaces]="0"
    [allowNegative]="false"
    [max]="999999"
  ></jf-form-field-number>
</jf-form-block>
