import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {EinstellungenService} from '../../openapi/fakturierung-openapi';
import {concatMap, of} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {InhaberEntitiesActions, mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {EinstellungenEntitiesActions} from '../actions/einstellungen-entities.actions';
import {MatSnackBar} from '@angular/material/snack-bar';


@Injectable()
export class EinstellungenEntitiesEffects {

  private _actions$ = inject(Actions);
  private _logger = inject(NGXLogger);
  private _einstellungenService = inject(EinstellungenService);
  private _snackbar = inject(MatSnackBar);

  /**
   * Startet den Ladevorgang der Standardeinstellungen, sobald die Betrieb-ID aus der URL bekannt ist.
   * Bei jeder Änderung der Betrieb-ID werden die Einrichtungen neu geladen.
   */
  readonly setCurrentInhaberIdFromURL$ = createEffect(
    () => this._actions$.pipe(
      ofType(InhaberEntitiesActions.setCurrentInhaberIdFromURL),
      map(action => action.currentInhaberId),

      // INFO: Filtert ungültige IDs heraus
      filter((currentInhaberId): currentInhaberId is string => !!currentInhaberId),

      // INFO: Leitet die GET-Action mit der aktuellen Betrieb-ID weiter
      map(currentInhaberId => EinstellungenEntitiesActions.getEinstellungenDefault({betriebId: currentInhaberId})),
    ),
  );

  /**
   * Effekt zum Laden der Standardeinstellungen pro Betrieb.
   * Löst den GET-Request über den EinstellungenService aus und dispatcht bei Erfolg die Success-Action,
   * bei Fehlern die Failure-Action.
   */
  readonly getEinstellungenDefault = createEffect(
    () => this._actions$.pipe(
      ofType(EinstellungenEntitiesActions.getEinstellungenDefault),
      concatMap(({betriebId}) => {

        // INFO: Dispatch des GET-Requests für die Einstellungen
        return this._einstellungenService.getEinstellungenDefault(betriebId).pipe(
          map((einstellungen) => {

            // INFO: Logging des erfolgreichen Abrufs
            this._logger.debug(
              'read default settings by ID succeeded. settings:',
              einstellungen,
            );

            // INFO: Dispatch der Success-Action mit den abgerufenen Einstellungen und der Betrieb-ID
            return EinstellungenEntitiesActions.getEinstellungenDefaultSuccess({betriebId, einstellungen});
          }),
          catchError(error => of(error).pipe(
            // INFO: Mapping des HTTP-Fehlers in ein serialisiertes Format
            mappedHttpErrorResponseOperator(error),
            map(error => {

              // INFO: Logging des Fehlers
              this._logger.error(
                'read default settings by ID failed. betriebId:',
                betriebId,
                'error:',
                error,
              );

              // INFO: Dispatch der Failure-Action mit der Betrieb-ID und dem Fehler
              return EinstellungenEntitiesActions.getEinstellungenDefaultFailure({
                betriebId,
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  /**
   * Effekt zur Fehlerbehandlung des GET-Requests für Standardeinstellungen.
   * Zeigt bei einem Fehler eine Snackbar mit einer Fehlermeldung an.
   */
  readonly getEinstellungenDefaultFailure$ = createEffect(
    () => this._actions$.pipe(
      ofType(EinstellungenEntitiesActions.getEinstellungenDefaultFailure),
      map(({error}) => {
        let errorMsg = '';
        switch (error.status) {
          // TODO: Spezifische Fehlercodes einbinden, sobald diese in der OpenAPI definiert sind
          default: {
            errorMsg = 'Fehler beim Laden der Standardeinstellungen. Bitte probiere es später erneut.';
          }
        }

        // INFO: Anzeige der Fehlermeldung in einer Snackbar
        this._snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
      })
    ),
    {dispatch: false},
  );

  /**
   * Effekt zum Aktualisieren der Standardeinstellungen pro Betrieb.
   * Löst den UPDATE-Request über den EinstellungenService aus und dispatcht bei Erfolg die Success-Action,
   * bei Fehlern die Failure-Action.
   */
  readonly updateEinstellungenDefault = createEffect(
    () => this._actions$.pipe(
      ofType(EinstellungenEntitiesActions.updateEinstellungenDefault),
      concatMap(({betriebId, einstellungen}) => {

        // INFO: Dispatch des UPDATE-Requests für die Einstellungen
        return this._einstellungenService.updateEinstellungenDefault(betriebId, einstellungen).pipe(
          map((einstellungenResponse) => {

            // INFO: Logging des erfolgreichen Updates
            this._logger.debug(
              'update default settings succeeded. betriebId:',
              betriebId,
            );

            // INFO: Dispatch der Success-Action mit der Betrieb-ID und den aktualisierten Einstellungen
            return EinstellungenEntitiesActions.updateEinstellungenDefaultSuccess({
              betriebId,
              einstellungen: einstellungenResponse
            });
          }),
          catchError(error => of(error).pipe(
            // INFO: Mapping des HTTP-Fehlers in ein serialisiertes Format
            mappedHttpErrorResponseOperator(error),
            map(error => {

              // INFO: Logging des Fehlers beim Update
              this._logger.error(
                'update default settings failed. betriebId:',
                betriebId,
                'error:',
                error,
              );

              // INFO: Dispatch der Failure-Action mit der Betrieb-ID und dem Fehler
              return EinstellungenEntitiesActions.updateEinstellungenDefaultFailure({
                betriebId,
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  /**
   * Effekt zur Fehlerbehandlung des UPDATE-Requests für Standardeinstellungen.
   * Zeigt bei einem Fehler eine Snackbar mit einer Fehlermeldung an.
   */
  readonly updateEinstellungenDefaultFailure$ = createEffect(
    () => this._actions$.pipe(
      ofType(EinstellungenEntitiesActions.updateEinstellungenDefaultFailure),
      map(({error}) => {
        let errorMsg = '';
        switch (error.status) {
          // TODO: Spezifische Fehlercodes einbinden, sobald diese in der OpenAPI definiert sind
          default: {
            errorMsg = 'Fehler beim Aktualisieren der Standardeinstellungen. Bitte probiere es später erneut.';
          }
        }

        // INFO: Anzeige der Fehlermeldung in einer Snackbar
        this._snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
      })
    ),
    {dispatch: false},
  );
}
