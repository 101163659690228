<div class="dialog-wrapper">
  <h1 class="dialog-h1" i18n> Löschen nicht mehr möglich </h1>
  <div class="dialog-body">
    Dieser Beleg wurde bereits in deiner Buchführung revisionssicher gespeichert.
    <br><br>
    Möchtest du diesen Beleg zurücknehmen oder korrigieren, so wird dieser in jedem Fall storniert. Er verbleibt aber bei einer
    Betriebsprüfung sichtbar in deinen Buchführungsdaten.
  </div>
  <br><br>
  <div class="button-wrapper">
    <jf-button [variant]="'primary'" (doClick)="doCancelFakturierungsbeleg()" i18n> Beleg stornieren </jf-button>
    <jf-button [variant]="'ghost'" (doClick)="doCloseDialog()" i18n> Abbrechen </jf-button>
  </div>
</div>
