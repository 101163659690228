import {DestroyRef, inject, Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, ReplaySubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


export interface LoggingConfig {
  level: number,
}

export interface AuthConfig {
  authority: string,
  clientId: string,
  scope: string,
  logLevel: number,
  renewTimeBeforeTokenExpiresInSeconds: number,
}

export interface FakturierungConfig {
  apiBaseUrl: string,
}

export interface PortalConfig {
  targetOrigin: string,
}

export interface SentryConfig {
  enabled: boolean,
  debug: boolean,
  dsn: string,
  sampleRate: number,
}

export interface MatomoConfig {
  enabled: boolean,
  trackerUrl: string,
  siteId: string,
}

export interface IConfig {
  environment: string,
  logging: LoggingConfig,
  auth: AuthConfig,
  fakturierung: FakturierungConfig,
  portal: PortalConfig,
  sentry: SentryConfig,
  matomo: MatomoConfig,
}

export class Config
  implements IConfig {

  constructor(
    public environment: string,
    public logging: LoggingConfig,
    public auth: AuthConfig,
    public fakturierung: FakturierungConfig,
    public portal: PortalConfig,
    public sentry: SentryConfig,
    public matomo: MatomoConfig,
  ) {
  }
}

@Injectable()
export class ConfigService
  implements OnDestroy {

  private _destroyRef = inject(DestroyRef);
  private _httpClient = inject(HttpClient);

  private _loaded = new ReplaySubject<Config>();

  private _config_?: Config;

  ngOnDestroy() {
    this._loaded.complete();
  }

  get loaded$() {
    return this._loaded.pipe(
      takeUntilDestroyed(this._destroyRef),
    );
  }

  get config(): Config {
    return this._config_ as Config;
  }

  loadConfig() {
    return forkJoin([
      this.getConfig(),
    ]);
  }

  getConfig(): Observable<Config> {
    const url = '/assets/config/config.json';
    return this._httpClient.get<IConfig>(url)
      .pipe(
        tap(config => {
          this._config_ = new Config(
            config.environment,
            config.logging,
            config.auth,
            config.fakturierung,
            config.portal,
            config.sentry,
            config.matomo,
          );
          this._loaded.next(this._config_);
        }),
      );
  }
}
