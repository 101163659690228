<mat-dialog-content>
  <jf-form-wrapper
    [title]="title"
    [buttons]="formActionButtons"
    [padding]="false"
  >
    <ng-container header>
      <div class="header-content">
        <p *ngIf="!!kundeDto?.id">
          Änderungen an bestehenden Kontaktdaten führen automatisch zur Aktualisierung der zugehörigen
          <b>Rechnungsentwürfe</b>.
        </p>
        <jf-radio-button-group
          [formControl]="formControls.kundendatenType"
          [options]="kundendatenTypes"
          *ngIf="kundendatenTypes"
        ></jf-radio-button-group>
      </div>
    </ng-container>
    <ng-container content>
      <app-kunde-geschaeftskunde-form
        *ngIf="selectedAbsenderType && selectedAbsenderType === 'Geschaeftskundendaten'"
        [formControls]="formControls.geschaeft"
        [laender]="laender"
        [anreden]="anreden"
        [kunde]="kundeDto"
        (kundeChanged)="kundeChanged($event)"
      ></app-kunde-geschaeftskunde-form>

      <app-kunde-privatperson-form
        *ngIf="selectedAbsenderType && selectedAbsenderType === 'Privatkundendaten'"
        [formControls]="formControls.privat"
        [laender]="laender"
        [anreden]="anreden"
        [kunde]="kundeDto"
        (kundeChanged)="kundeChanged($event)"
      ></app-kunde-privatperson-form>
    </ng-container>
  </jf-form-wrapper>
</mat-dialog-content>
