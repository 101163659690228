import {createAction, props} from '@ngrx/store';
import {BelegDTO, CreateBelegRequestDTO, ProblemDTO, UpdateBelegRequestDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class FakturierungsbelegEntitiesActions {

  private static readonly prefix = '[Fakturierungsbeleg Entities] ';

  public static readFakturierungsbelegeSuccess = createAction(
    this.prefix + 'Read list of fakturierungsbelege successfully.',
    props<{
      belegDtos: BelegDTO[]
    }>(),
  );

  public static readFakturierungsbelegeFailure = createAction(
    this.prefix + 'Read list of fakturierungsbelege failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static countFakturierungsbelegeElementsSuccess = createAction(
    this.prefix + 'Count list of fakturierungsbelege elements successfully.',
    props<{
      totalElements: number,
    }>(),
  );

  public static countFakturierungsbelegePagesSuccess = createAction(
    this.prefix + 'Count list of fakturierungsbelege pages successfully.',
    props<{
      totalPages: number,
    }>(),
  );

  public static loadFakturierungsbelegByIdIfAbsent = createAction(
    this.prefix + 'Load fakturierungsbeleg by ID if absent.',
    props<{
      betriebId: string;
      belegId: string;
    }>(),
  );

  public static getFakturierungsbelegById = createAction(
    this.prefix + 'Get fakturierungsbeleg by ID.',
    props<{
      betriebId: string;
      belegId: string;
    }>(),
  );

  public static getFakturierungsbelegByIdSuccess = createAction(
    this.prefix + 'Get fakturierungsbeleg successfully.',
    props<{
      belegDto: BelegDTO;
    }>(),
  );

  public static getFakturierungsbelegByIdFailure = createAction(
    this.prefix + 'Get fakturierungsbeleg failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static createFakturierungsbeleg = createAction(
    this.prefix + 'Create fakturierungsbeleg.',
    props<{
      betriebId: string;
      createBelegRequestDto: CreateBelegRequestDTO;
      redirectToForm?: boolean;
    }>(),
  );

  public static createFakturierungsbelegWithCurrentBetriebId = createAction(
    this.prefix + 'Create fakturierungsbeleg with current betrieb.',
    props<{
      redirectToForm?: boolean;
    }>(),
  );

  public static createFakturierungsbelegSuccess = createAction(
    this.prefix + 'Create fakturierungsbeleg successfully.',
    props<{
      belegDto: BelegDTO;
    }>(),
  );

  public static createFakturierungsbelegFailure = createAction(
    this.prefix + 'Create fakturierungsbeleg failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static updateFakturierungsbeleg = createAction(
    this.prefix + 'Update fakturierungsbeleg.',
    props<{
      betriebId: string;
      belegId: string;
      updateBelegRequestDto: UpdateBelegRequestDTO;
      fetchPositions: boolean;
    }>(),
  );

  public static updateFakturierungsbelegSuccess = createAction(
    this.prefix + 'Update fakturierungsbeleg successfully.',
    props<{
      belegDto: BelegDTO;
    }>(),
  );

  public static updateFakturierungsbelegFailure = createAction(
    this.prefix + 'Update fakturierungsbeleg failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static deleteFakturierungsbeleg = createAction(
    this.prefix + 'Delete fakturierungsbeleg.',
    props<{
      betriebId: string;
      belegId: string;
    }>(),
  );

  public static deleteFakturierungsbelegSuccess = createAction(
    this.prefix + 'Delete fakturierungsbeleg successfully.',
    props<{
      belegId: string;
    }>(),
  );

  public static deleteFakturierungsbelegFailure = createAction(
    this.prefix + 'Delete fakturierungsbeleg failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static finalizeFakturierungsbeleg = createAction(
    this.prefix + 'Finalize fakturierungsbeleg.',
    props<{
      betriebId: string;
      belegId: string;
      openPreview: boolean;
      downloadPdf: boolean;
    }>(),
  );

  public static finalizeFakturierungsbelegSuccess = createAction(
    this.prefix + 'Finalize fakturierungsbeleg successfully.',
    props<{
      belegDto: BelegDTO;
    }>(),
  );

  public static finalizeFakturierungsbelegFailure = createAction(
    this.prefix + 'Finalize fakturierungsbeleg failed.',
    props<{
      error: MappedHttpErrorResponse<ProblemDTO>,
    }>(),
  );


  public static cancelFakturierungsbeleg = createAction(
    this.prefix + 'Cancel fakturierungsbeleg.',
    props<{
      betriebId: string;
      belegId: string;
      openPreview: boolean;
      downloadPdf: boolean;
    }>(),
  );

  public static cancelFakturierungsbelegSuccess = createAction(
    this.prefix + 'Cancel fakturierungsbeleg successfully.',
    props<{
      cancelledBeleg: BelegDTO;
    }>(),
  );

  public static cancelFakturierungsbelegFailure = createAction(
    this.prefix + 'Cancel fakturierungsbeleg failed.',
    props<{
      error: MappedHttpErrorResponse<ProblemDTO>,
    }>(),
  );

  public static sendBelegWithMailTo = createAction(
    this.prefix + 'Send fakturierungsbeleg with mail to function.',
    props<{
      belegDto: BelegDTO;
    }>(),
  );

  public static validateFakturierungsbeleg = createAction(
    this.prefix + 'Validate specific fakturierungsbeleg.',
    props<{
      betriebId: string,
      belegId: string,
    }>(),
  );

  public static validateFakturierungsbelegFailure = createAction(
    this.prefix + 'Validation of specific fakturierungsbeleg failed.',
    props<{
      belegId: string,
      error: MappedHttpErrorResponse<ProblemDTO>,
    }>(),
  );

  public static validateFakturierungsbelegSuccess = createAction(
    this.prefix + 'Validation of specific fakturierungsbeleg succeeded.',
    props<{
      belegId: string,
    }>(),
  );

}
