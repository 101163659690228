import {Injectable, Injector} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FakturierungsbelegEntitiesActions} from '../actions/fakturierungsbeleg-entities.actions';
import {concatMap, of, switchMap, take, tap, withLatestFrom} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {ActionCreator, Store} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {
  BelegDTO,
  BelegService,
  CreateBelegRequestDTO,
  PageableDTO,
  ProblemDTO, ValidationService,
} from '../../openapi/fakturierung-openapi';
import {
  InhaberEntitiesSelectors,
  MappedHttpErrorResponse,
  mappedHttpErrorResponseOperator,
  RouterActions
} from '@adnova/jf-ng-components';
import {TypedAction} from '@ngrx/store/src/models';
import {FakturierungsbelegEntitiesSelectors} from '../selectors/fakturierungsbeleg-entities.selectors';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {SentryActions} from '../actions/sentry.actions';
import {FakturierungsbelegContentActions} from '../actions/fakturierungsbeleg-content.actions';
import {KundeEntitiesSelectors} from '../selectors/kunde-entities.selectors';
import {FakturierungsbelegTableActions} from '../actions/fakturierungsbeleg-table.actions';
import {EinstellungenEntitiesSelectors} from '../selectors/einstellungen-entities.selectors';
import {IntentActionService} from '../../services/intent-action.service';


@Injectable()
export class FakturierungsbelegEntitiesEffects {

  private _intentActionService?: IntentActionService;

  get intentActionService(): IntentActionService {
    if (!this._intentActionService) {
      this._intentActionService = this.injector.get(IntentActionService);
    }

    return this._intentActionService;
  }

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private belegService: BelegService,
    private validationService: ValidationService,
    private store: Store<AppState>,
    private injector: Injector,
  ) {
  }

  /**
   * Der Effekt erlaubt asynchrones Laden von Fakturierungsbelegen durch serverseitige Datenanfragen.
   * Dabei steuert er die Auslösung von Actions basierend auf dem Erfolg oder Misserfolg der Anfrage.
   *
   * @property onAction - Action Creator, der ausgeführt wird, um den Effekt zu starten
   * @property successAction - Action Creator, der ausgeführt wird, wenn die Daten erfolgreich vom Server abgerufen wurden
   * @property failureAction - Action Creator, der ausgeführt wird, wenn beim Abrufen der Daten ein Fehler aufgetreten ist
   */
  readonly readFakturierungsbelege$ = (
    onAction: ActionCreator<any, (props: {
      betriebId: string,
      pageableDto: PageableDTO,
    }) => ({
      betriebId: string, pageableDto: PageableDTO,
    } & TypedAction<any>)>,
    successAction?: ActionCreator<any, (props: { belegDtos: BelegDTO[], }) => ({
      belegDtos: BelegDTO[]
    } & TypedAction<any>)>,
    failureAction?: ActionCreator<any, (props: { error: MappedHttpErrorResponse, }) => ({
      error: MappedHttpErrorResponse
    } & TypedAction<any>)>,
  ) => createEffect(
    () => this.actions$.pipe(
      ofType(onAction),
      switchMap(({betriebId, pageableDto}) => {
        return this.belegService.readBelege(
          betriebId,
          pageableDto
        ).pipe(
          switchMap(belegPageDto => {
            this.logger.debug('read fakturierungsbelege succeeded.');

            return [
              FakturierungsbelegEntitiesActions.readFakturierungsbelegeSuccess({belegDtos: belegPageDto.content}),
              FakturierungsbelegEntitiesActions.countFakturierungsbelegeElementsSuccess({totalElements: belegPageDto.totalElements}),
              FakturierungsbelegEntitiesActions.countFakturierungsbelegePagesSuccess({totalPages: Math.ceil(belegPageDto.totalElements / (belegPageDto.pageable.size || 1))}),
              ...(successAction ? [successAction({belegDtos: belegPageDto.content})] : []),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            switchMap(error => {
              this.logger.error('read fakturierungsbelege failed.', 'error:', error);

              return [
                FakturierungsbelegEntitiesActions.readFakturierungsbelegeFailure({error}),
                ...(failureAction ? [failureAction({error})] : []),
              ];
            }),
          )),
        );
      }),
    )
  );

  /**
   * Generisches Error-Handling für das Laden von Fakturierungsbelegen.
   */
  readonly readFakturierungsbelegeFailed$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.readFakturierungsbelegeFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden von Fakturierungsbelegen. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Keine Fakturierungsbelege gefunden.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Fakturierungsbelege. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          });
      }),
    ), {dispatch: false}
  );

  /**
   * Effekt zum Laden eines Fakturierungsbelegs anhand der ID, sofern er noch nicht im Store vorhanden ist.
   */
  readonly loadFakturierungsbelegByIdIfAbsent$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.loadFakturierungsbelegByIdIfAbsent),
      concatMap(action => this.store.select(FakturierungsbelegEntitiesSelectors.belegById(action.belegId)).pipe(
        take(1),
        map(beleg => ({action, beleg}))
      )),
      filter(data => !data.beleg),
      map(data => FakturierungsbelegEntitiesActions.getFakturierungsbelegById({
        betriebId: data.action.betriebId,
        belegId: data.action.belegId,
      })),
    ),
  );

  /**
   * Effekt zum Laden eines Fakturierungsbelegs anhand der ID.
   */
  readonly getFakturierungsbelegById$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.getFakturierungsbelegById),
      concatMap(({betriebId, belegId}) => {
        return this.belegService.getBeleg(betriebId, belegId).pipe(
          switchMap((belegDto) => {
            this.logger.debug(
              'read belegDto by ID succeeded. belegDto:',
              belegDto,
            );

            return [
              FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdSuccess({belegDto}),
              FakturierungsbelegEntitiesActions.validateFakturierungsbeleg({
                betriebId: belegDto.betriebId,
                belegId: belegDto.id,
              }),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read beleg by ID failed. belegId:',
                belegId,
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdFailure({
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden eines Fakturierungsbelegs anhand der ID.
   */
  readonly getFakturierungsbelegByIdFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Fakturierungsbeleg nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
      })
    ),
    {dispatch: false},
  );

  /**
   * Effekt zum Erstellen eines Fakturierungsbelegs mit dem aktuell ausgewählten Betrieb.
   *
   * Dieser Effekt reagiert auf die Action, die den Fakturierungsbeleg erstellen soll.
   * Er führt folgende Schritte aus:
   * 1. Sobald die Action ausgelöst wird, wird die aktuell ausgewählte Betriebs-ID aus dem Store abgerufen.
   * 2. Es wird überprüft, ob eine gültige Betriebs-ID vorhanden ist.
   * 3. Mit der Betriebs-ID wird der Selector 'zahlungszielTageByBetrieb' verwendet, um den Wert für 'zahlungszielTage'
   *    (Zahlungsziel in Tagen) aus den Einstellungen zu holen.
   * 4. Mit den gesammelten Informationen wird ein DTO (Data Transfer Object) erstellt.
   * 5. Schließlich wird eine neue Action dispatcht, die den Fakturierungsbeleg mit den erstellten Daten anlegt.
   */
  readonly createFakturierungsbelegWithSelectedBetrieb$ = createEffect(() =>
    this.actions$.pipe(
      // INFO: 1. Schritt: Reagiere auf die Action 'createFakturierungsbelegWithCurrentBetriebId'
      ofType(FakturierungsbelegEntitiesActions.createFakturierungsbelegWithCurrentBetriebId),

      // INFO: 2. Kombiniere die Action mit der aktuell ausgewählten Betriebs-ID aus dem Store
      withLatestFrom(this.store.select(InhaberEntitiesSelectors.currentInhaberId)),

      // INFO: 3. Stelle sicher, dass eine gültige Betriebs-ID vorhanden ist (nicht null oder leer)
      filter(([, betriebId]) => !!betriebId),

      // INFO: 4. Verwende switchMap, um auf Basis der Betriebs-ID den Wert 'zahlungszielTage' abzurufen
      switchMap(([{redirectToForm}, betriebId]) =>
        this.store
          .select(EinstellungenEntitiesSelectors.zahlungszielTageByBetrieb(betriebId!))
          .pipe(
            // INFO: Nehme nur den ersten Wert, da wir nur einmal den aktuellen Wert benötigen
            take(1),
            // INFO: Erstelle ein Objekt mit allen benötigten Werten, um später den Beleg zu erstellen
            map(zahlungszielTage => ({
              redirectToForm,
              betriebId: betriebId!,
              zahlungszielTage,
            }))
          )
      ),

      // INFO: 5. Erstelle das DTO (Data Transfer Object) und dispatch die Action zum Erstellen des Fakturierungsbelegs
      map(({redirectToForm, betriebId, zahlungszielTage}) => {
        // INFO: Erstelle ein Objekt, das alle nötigen Daten für den Fakturierungsbeleg enthält
        const createBelegRequestDto: CreateBelegRequestDTO = {
          // INFO: Aktuelles Datum im Format YYYY-MM-DD
          datum: new Date().toISOString().split('T')[0],
          leistungsdatum: new Date().toISOString().split('T')[0],
          // INFO: Vorlaufzeile mit einer Dankesbotschaft
          vorlaufzeile:
            'Vielen Dank für Ihren Auftrag. Wir erlauben uns, die nachfolgenden Positionen zu berechnen:',
          // INFO: Zahlungsziel in Tagen (kann eine Zahl oder undefined sein)
          zahlungszielTage,
        };

        // INFO: Dispatch die Action, die den Fakturierungsbeleg erstellt, mit allen erforderlichen Informationen
        return FakturierungsbelegEntitiesActions.createFakturierungsbeleg({
          betriebId,
          createBelegRequestDto,
          redirectToForm,
        });
      })
    )
  );

  /**
   * Effekt zum Erstellen eines Fakturierungsbelegs.
   */
  readonly createFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.createFakturierungsbeleg),
      concatMap(({betriebId, createBelegRequestDto, redirectToForm}) => {
        return this.belegService.createBeleg(betriebId, createBelegRequestDto).pipe(
          concatMap(createdBelegDto => {
            this.logger.debug(
              'create fakturierungsbeleg entity succeeded. belegId:',
              createdBelegDto.id,
            );

            const successActions: TypedAction<any>[] = [
              FakturierungsbelegEntitiesActions.createFakturierungsbelegSuccess({belegDto: createdBelegDto}),
              FakturierungsbelegEntitiesActions.validateFakturierungsbeleg({
                betriebId: createdBelegDto.betriebId,
                belegId: createdBelegDto.id,
              }),
            ];

            if (redirectToForm) {
              successActions.push(FakturierungsbelegFormActions.redirectToForm({
                betriebId,
                belegId: createdBelegDto.id
              }));
            }

            return successActions;
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'create fakturierungsbeleg entity failed',
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.createFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Erstellen eines Fakturierungsbelegs.
   */
  readonly createFakturierungsbelegSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.createFakturierungsbelegSuccess),
      tap(() => {
        this.snackbar.open('Rechnung erfolgreich angelegt', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Erstellen eines Fakturierungsbelegs.
   */
  readonly createFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.createFakturierungsbelegFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Erstellen des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Erstellen des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Updaten eines Fakturierungsbelegs.
   */
  readonly updateFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.updateFakturierungsbeleg),
      concatMap(({betriebId, belegId, updateBelegRequestDto, fetchPositions: fetchPositionen}) => {
        return this.belegService.updateBeleg(betriebId, belegId, updateBelegRequestDto).pipe(
          switchMap(updatedBelegDto => {
            this.logger.debug(
              'update beleg entity succeeded. belegId:',
              belegId,
            );

            const actions: TypedAction<any>[] = [
              FakturierungsbelegEntitiesActions.updateFakturierungsbelegSuccess({
                belegDto: updatedBelegDto,
              }),
              FakturierungsbelegEntitiesActions.validateFakturierungsbeleg({
                betriebId: updatedBelegDto.betriebId,
                belegId: updatedBelegDto.id,
              }),
            ];

            if (fetchPositionen) {
              actions.push(FakturierungsbelegFormActions.fetchPositionen({belegId}));
            }

            return actions;
          }),

          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'update beleg entity failed. belegId:',
                belegId,
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.updateFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Updaten eines Fakturierungsbelegs.
   */
  readonly updateFakturierungsbelegSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.updateFakturierungsbelegSuccess),
      tap(() => {
        this.logger.debug('update beleg succeeded');
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Updaten eines Fakturierungsbelegs.
   */
  readonly updateFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.updateFakturierungsbelegFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Aktualisieren des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbeleg),
      concatMap(({betriebId, belegId}) => {
        return this.belegService.deleteBeleg(betriebId, belegId).pipe(
          switchMap(() => {
            this.logger.debug(
              'delete beleg succeeded. belegId:',
              belegId,
            );

            return [
              FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess({belegId}),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'delete beleg failed.',
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.deleteFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbelegSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbelegSuccess),
      tap(() => {
        this.snackbar.open('Rechnung erfolgreich gelöscht', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Löschen eines Fakturierungsbelegs.
   */
  readonly deleteFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.deleteFakturierungsbelegFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Löschen des Fakturierungsbelegs. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Fakturierungsbeleg nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen des Fakturierungsbelegs. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Festschreiben eines Fakturierungsbelegs.
   */
  readonly finalizeFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.finalizeFakturierungsbeleg),
      concatMap(({betriebId, belegId, openPreview, downloadPdf}) => {
        return this.belegService.schreibeBeleg(betriebId, belegId).pipe(
          concatMap(belegDto => {
            this.logger.debug(
              'finalize beleg succeeded. belegId:',
              belegId,
            );

            const actions: TypedAction<any>[] = [
              FakturierungsbelegEntitiesActions.finalizeFakturierungsbelegSuccess({belegDto}),
            ];

            if (openPreview) {
              actions.push(RouterActions.navigateByUrl({
                url: `/fakturierungsbelege/inhaber/` + betriebId + `/preview/fakturierungsbeleg/` + belegId
              }));

              actions.push(FakturierungsbelegEntitiesActions.sendBelegWithMailTo({
                belegDto,
              }));
            } else {
              actions.push(RouterActions.navigateByUrl({
                url: `/fakturierungsbelege/inhaber/` + betriebId
              }));
            }

            if (downloadPdf) {
              actions.push(FakturierungsbelegContentActions.downloadBelegPdf({
                betriebId,
                belegId,
                statusEntwurf: false,
                isPreviewMode: false,
                rechnungsnummer: belegDto.nummer || '',
              }));
            }

            /*
             * INFO:
             * Das Übertragen des festgeschriebenen Belegs zum Beleg-Service ist ein asynchroner Prozess.
             * Daher wird hier ein Timeout von 10 Sekunden gesetzt,
             * bevor der Intent an die Belege-Online App gesendet wird.
             *
             * Der Intent wird in der Belege-Online App verwendet, um die Belege nachzuladen.
             */
            setTimeout(() => {
              this.intentActionService.doFakturierungsbelegFestgeschriebenV1({
                fakturierungsbelegId: belegId,
                betriebId,
              });
            }, 10000);

            return actions;
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator<ProblemDTO>(error),
            map(error => {
              this.logger.error(
                'finalize beleg failed.',
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.finalizeFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Error-Handling für das Festschreiben eines Fakturierungsbelegs.
   */
  readonly finalizeFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.finalizeFakturierungsbelegFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Fertigstellen der Rechnung. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Rechnung nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          case 422 : {
            errorMsg = error.error?.detail || 'Die Rechnung erfüllt nicht die Anforderungen, um fertiggestellt werden zu können. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Fertigstellen der Rechnung. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          error.error?.detail ?? errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );


  /**
   * Effekt zum Stornieren eines Fakturierungsbelegs.
   */
  readonly cancelFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.cancelFakturierungsbeleg),
      concatMap(({betriebId, belegId, openPreview, downloadPdf}) => {
        return this.belegService.storniereBeleg(betriebId, belegId).pipe(
          concatMap((belegDto: BelegDTO) => {
            this.logger.debug('cancel beleg succeeded. belegId:', belegId);
            this.snackbar.open('Hinweis: Bitte übersende deinem Kunden diese Rechnungskorrektur.', undefined, {duration: 20000});

            const actions: TypedAction<any>[] = [
              FakturierungsbelegEntitiesActions.cancelFakturierungsbelegSuccess({cancelledBeleg: belegDto}),
              FakturierungsbelegTableActions.addNewItemToDisplayedIds({belegId: belegDto.id}),
              FakturierungsbelegEntitiesActions.validateFakturierungsbeleg({
                betriebId: belegDto.betriebId,
                belegId: belegDto.id,
              }),
            ];

            if (openPreview) {
              actions.push(RouterActions.navigateByUrl({
                url: `/fakturierungsbelege/inhaber/` + betriebId + `/preview/fakturierungsbeleg/` + belegDto.id
              }));
            } else {
              actions.push(RouterActions.navigateByUrl({
                url: `/fakturierungsbelege/inhaber/` + betriebId
              }));
            }

            if (downloadPdf) {
              actions.push(FakturierungsbelegContentActions.downloadBelegPdf({
                betriebId,
                belegId,
                statusEntwurf: false,
                isPreviewMode: false,
                rechnungsnummer: belegDto.nummer || '',
              }));
            }

            /*
             * INFO:
             * Das Übertragen des stornierten Belegs zum Beleg-Service ist ein asynchroner Prozess.
             * Daher wird hier ein Timeout von 10 Sekunden gesetzt,
             * bevor der Intent an die Belege-Online App gesendet wird.
             *
             * Der Intent wird in der Belege-Online App verwendet, um die Belege nachzuladen.
             */
            setTimeout(() => {
              this.intentActionService.doFakturierungsbelegStorniertV1({
                fakturierungsbelegId: belegId,
                betriebId,
              });
            }, 10000);

            return actions;
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator<ProblemDTO>(error),
            map(error => {
              this.logger.error(
                'cancel beleg failed.',
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.cancelFakturierungsbelegFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );


  /**
   * Error-Handling für das Stornieren eines Fakturierungsbelegs.
   */
  readonly cancelFakturierungsbelegFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.cancelFakturierungsbelegFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Stornieren der Rechnung. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Rechnung nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          case 422 : {
            errorMsg = error.error?.detail || 'Die Rechnung erfüllt nicht die Anforderungen, um storniert werden zu können. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Stornieren der Rechnung. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly sendBelegWithMailTo$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.sendBelegWithMailTo),
      tap(({belegDto}) => {
        if (!belegDto.status.offen) {
          this.store.select(KundeEntitiesSelectors.kundenById(belegDto?.kundeId || '')).pipe(
            take(1),
          ).subscribe(kunde => {
            // INFO: Mailto-Link zusammenbauen
            const receiver = kunde?.kundendaten.emailAdresse || '';
            let subjectText: string;
            let bodyText: string;
            let snackbarText: string;

            if (belegDto.originalBelegId) {
              subjectText = 'Rechnungskorrektur';
              bodyText = 'anbei erhalten Sie die Rechnungskorrektur zu der zuvor ausgestellten, nun stornierten, Rechnung.';
              snackbarText = 'Rechnungskorrektur';
            } else if (belegDto.korrekturBelegId) {
              subjectText = 'Stornierte Rechnung';
              bodyText = 'im Anhang finden Sie die stornierte Originalrechnung zu Ihren beauftragten Leistungen/Lieferungen.';
              snackbarText = 'stornierte Originalrechnung';
            } else {
              subjectText = 'Rechnung';
              bodyText = 'im Anhang finden Sie die Rechnung zu Ihren beauftragten Leistungen/Lieferungen.';
              snackbarText = 'Rechnung';
            }

            const subject = encodeURIComponent(`${subjectText} ` + belegDto.nummer);

            const body =
              encodeURIComponent('Hallo,') +
              '%0D%0A%0D%0A' + // INFO: Zeilenumbrüche
              encodeURIComponent(bodyText) +
              '%0D%0A' + // INFO: Zeilenumbrüche
              encodeURIComponent('Sollten Sie Fragen haben, stehen wir Ihnen gerne zur Verfügung.') +
              '%0D%0A%0D%0A' + // INFO: Zeilenumbrüche
              encodeURIComponent('Mit freundlichen Grüßen');

            const mailtoLink = `mailto:${receiver}?subject=${subject}&body=${body}`;

            // INFO: Mailto-Link öffnen, um den nativen E-Mail-Client zu starten.
            window.open(mailtoLink);

            // INFO: Den Anwender erinnern die Rechnung, als Anhang hinzuzufügen.
            this.snackbar.open(`Bitte die ${snackbarText} der E-Mail als Anhang hinzufügen. \nDie ${snackbarText} befindet sich in deinem Download-Ordner.`, undefined, {duration: 15000});
          });
        } else {
          this.logger.debug('it is not allowed to send a beleg with status of offen. belegDto:', belegDto);
        }
      }),
    ), {dispatch: false}
  );

  /**
   * Effekt zum Validieren eines Fakturierungsbelegs
   */
  readonly validateFakturierungsbeleg$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.validateFakturierungsbeleg),
      concatMap(({betriebId, belegId}) => {
        return this.validationService.validate(betriebId, belegId).pipe(
          switchMap(() => {
            this.logger.debug('validate beleg succeeded. belegId:', belegId);

            return [
              FakturierungsbelegEntitiesActions.validateFakturierungsbelegSuccess({belegId}),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator<ProblemDTO>(error),
            map(error => {
              this.logger.error(
                'validate beleg failed.',
                'error:',
                error,
              );

              return FakturierungsbelegEntitiesActions.validateFakturierungsbelegFailure({
                belegId,
                error,
              });
            }),
          )),
        );
      }),
    )
  );

}
