import {createReducer, on} from '@ngrx/store';
import {einstellungenEntitiesAdapter} from '../adapters/einstellungen-entities.adapter';
import {EinstellungenEntitiesActions} from '../actions/einstellungen-entities.actions';
import {EinstellungenEntitiesState} from '../states/einstellungen-entities.state';


/**
 * Initialer State für die Einstellungen-Entities.
 * Enthält neben den Entities auch Arrays zur Statusverwaltung von GET- und UPDATE-Anfragen.
 * Die Arrays dienen dazu, den aktuellen Lade-, Erfolg- und Fehlerstatus pro Betrieb (identifiziert durch betriebId)
 * zu verfolgen, da es nur ein Einstellungen-Objekt pro Betrieb gibt.
 */
export const initialEinstellungenEntitiesState: EinstellungenEntitiesState = einstellungenEntitiesAdapter.getInitialState({
  getRequestsLoading: [],
  updateRequestsLoading: [],
  getRequestsError: [],
  updateRequestsError: [],
  getRequestsSuccess: [],
  updateRequestsSuccess: [],
});

export const einstellungenEntitiesReducer = createReducer(
  initialEinstellungenEntitiesState,

  /**
   * Startet einen GET-Request für die Standardeinstellungen.
   * Fügt die Betrieb-ID zu getRequestsLoading hinzu, sofern diese noch nicht vorhanden ist.
   *
   * Begründung: Da es pro Betrieb nur ein Einstellungen-Objekt gibt, wird die Betrieb-ID als eindeutiger Schlüssel
   * verwendet, um den Ladevorgang zu tracken. So können parallele Anfragen für unterschiedliche Betriebe separat verfolgt werden.
   */
  on(EinstellungenEntitiesActions.getEinstellungenDefault, (state, {betriebId}) => ({
    ...state,
    // INFO: Hinzufügen der Betrieb-ID zum Ladestatus, sofern die ID noch nicht vorhanden ist.
    getRequestsLoading: state.getRequestsLoading.includes(betriebId) ? state.getRequestsLoading : [...state.getRequestsLoading, betriebId],
  })),

  /**
   * Behandelt den Erfolg eines GET-Requests für Standardeinstellungen.
   * Upsertet das erhaltene Einstellungen-Objekt, entfernt die Betrieb-ID aus den Lade- und Fehler-Arrays
   * und fügt sie in das Erfolgs-Array ein.
   *
   * Begründung: Das Upsert (Update or Insert) stellt sicher, dass das Einstellungen-Objekt im Store immer aktuell ist.
   * Gleichzeitig wird der Erfolg dokumentiert, indem die Betrieb-ID in das Erfolgs-Array aufgenommen und aus den
   * Lade- und Fehler-Arrays entfernt wird.
   */
  on(EinstellungenEntitiesActions.getEinstellungenDefaultSuccess, (state, {betriebId, einstellungen}) => {
    return einstellungenEntitiesAdapter.upsertOne(einstellungen, {
      ...state,
      // INFO: Entfernen der Betrieb-ID aus dem Lade- und Fehlerstatus
      getRequestsLoading: state.getRequestsLoading.filter(id => id !== betriebId),
      getRequestsError: state.getRequestsError.filter(id => id !== betriebId),

      // INFO: Hinzufügen der Betrieb-ID zum Erfolgsstatus, sofern die ID noch nicht vorhanden ist.
      getRequestsSuccess: state.getRequestsSuccess.includes(betriebId) ? state.getRequestsSuccess : [...state.getRequestsSuccess, betriebId],
    });
  }),

  /**
   * Behandelt das Scheitern eines GET-Requests für Standardeinstellungen.
   * Entfernt die Betrieb-ID aus den Lade- und Erfolgs-Arrays und fügt sie zum Fehler-Array hinzu.
   *
   * Begründung: Im Fehlerfall wird der Lade- und Erfolgstatus zurückgesetzt, damit der Fehler eindeutig dokumentiert wird.
   * Dies ermöglicht eine getrennte Fehlerbehandlung für jeden Betrieb und erleichtert das erneute Anstoßen der Anfrage.
   */
  on(EinstellungenEntitiesActions.getEinstellungenDefaultFailure, (state, {betriebId}) => ({
    ...state,
    // INFO: Entfernen der Betrieb-ID aus dem Lade- und Erfolgsstatus
    getRequestsLoading: state.getRequestsLoading.filter(id => id !== betriebId),
    getRequestsSuccess: state.getRequestsSuccess.filter(id => id !== betriebId),

    // INFO: Hinzufügen der Betrieb-ID zum Fehlerstatus, sofern die ID noch nicht vorhanden ist.
    getRequestsError: state.getRequestsError.includes(betriebId) ? state.getRequestsError : [...state.getRequestsError, betriebId],
  })),

  /**
   * Startet einen UPDATE-Request für die Standardeinstellungen.
   * Fügt die Betrieb-ID zu updateRequestsLoading hinzu, sofern diese noch nicht vorhanden ist.
   *
   * Begründung: Das Update wird separat getrackt, da es von der GET-Anfrage unabhängig ist. Dadurch kann der Status
   * des Update-Vorgangs klar von Lade- oder Fehlerzuständen bei GET-Anfragen unterschieden werden.
   */
  on(EinstellungenEntitiesActions.updateEinstellungenDefault, (state, {betriebId}) => ({
    ...state,
    // INFO: Hinzufügen der Betrieb-ID zum Update-Ladevorgang, sofern die ID noch nicht vorhanden ist.
    updateRequestsLoading: state.updateRequestsLoading.includes(betriebId) ? state.updateRequestsLoading : [...state.updateRequestsLoading, betriebId],
  })),

  /**
   * Behandelt den Erfolg eines UPDATE-Requests für die Standardeinstellungen.
   * Upsertet das aktualisierte Einstellungen-Objekt, entfernt die Betrieb-ID aus den Update-Lade- und Fehler-Arrays
   * und fügt sie in das Erfolgs-Array ein.
   *
   * Begründung: Nach einem erfolgreichen Update soll der Store den neuen Zustand widerspiegeln.
   * Daher wird das Objekt via Upsert aktualisiert. Gleichzeitig wird der Erfolg dokumentiert, indem der
   * Update-Ladevorgang beendet und der Erfolg in einem separaten Array festgehalten wird.
   */
  on(EinstellungenEntitiesActions.updateEinstellungenDefaultSuccess, (state, {betriebId, einstellungen}) => {
    return einstellungenEntitiesAdapter.upsertOne(einstellungen, {
      ...state,
      // INFO: Entfernen der Betrieb-ID aus dem Lade- und Fehlerstatus
      updateRequestsLoading: state.updateRequestsLoading.filter(id => id !== betriebId),
      updateRequestsError: state.updateRequestsError.filter(id => id !== betriebId),

      // INFO: Hinzufügen der Betrieb-ID zum Erfolgsstatus, sofern die ID noch nicht vorhanden ist.
      updateRequestsSuccess: state.updateRequestsSuccess.includes(betriebId) ? state.updateRequestsSuccess : [...state.updateRequestsSuccess, betriebId],
    });
  }),

  /**
   * Behandelt das Scheitern eines UPDATE-Requests für die Standardeinstellungen.
   * Entfernt die Betrieb-ID aus den Update-Lade- und Erfolgs-Arrays und fügt sie zum Fehler-Array hinzu.
   *
   * Begründung: Bei einem Update-Fehler soll der Zustand so angepasst werden, dass der Fehlerstatus eindeutig ist.
   * Daher werden alle Spuren des laufenden Update-Vorgangs entfernt und die Betrieb-ID im Fehler-Array erfasst,
   * um eine erneute Anfrage zu ermöglichen und den Fehler gezielt anzuzeigen.
   */
  on(EinstellungenEntitiesActions.updateEinstellungenDefaultFailure, (state, {betriebId}) => ({
    ...state,
    // INFO: Entfernen der Betrieb-ID aus dem Lade- und Erfolgsstatus
    updateRequestsLoading: state.updateRequestsLoading.filter(id => id !== betriebId),
    updateRequestsSuccess: state.updateRequestsSuccess.filter(id => id !== betriebId),

    // INFO: Hinzufügen der Betrieb-ID zum Fehlerstatus, sofern die ID noch nicht vorhanden ist.
    updateRequestsError: state.updateRequestsError.includes(betriebId) ? state.updateRequestsError : [...state.updateRequestsError, betriebId],
  })),
);
