import {Component, inject, OnInit} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {FakturierungsbelegEntitiesSelectors} from '../../../store/selectors/fakturierungsbeleg-entities.selectors';
import {take} from 'rxjs';
import {BelegDTO} from '../../../openapi/fakturierung-openapi';
import {NGXLogger} from 'ngx-logger';
import {CancelFakturierungsbelegDialogActions} from '../../../store/actions/cancel-fakturierungsbeleg-dialog.actions';
import {
  CancelFakturierungsbelegDialogSelectors
} from '../../../store/selectors/cancel-fakturierungsbeleg-dialog.selectors';
import {FakturierungsbelegEntitiesActions} from '../../../store/actions/fakturierungsbeleg-entities.actions';


@Component({
  selector: 'app-cancel-fakturierungsbeleg-dialog',
  templateUrl: './cancel-fakturierungsbeleg-dialog.component.html',
  styleUrls: ['./cancel-fakturierungsbeleg-dialog.component.scss']
})
export class CancelFakturierungsbelegDialogComponent implements OnInit {
  private _store = inject(Store<AppState>);
  private _logger = inject(NGXLogger);
  private _fakturierungsbelegDto?: BelegDTO;

  ngOnInit() {
    this.getBeleg();
  }

  protected doCancelFakturierungsbeleg(): void {
    this._store.dispatch(FakturierungsbelegEntitiesActions.cancelFakturierungsbeleg({
      betriebId: this._fakturierungsbelegDto!.betriebId,
      belegId: this._fakturierungsbelegDto!.id,
      openPreview: true,
      downloadPdf: false
    }));
    this.doCloseDialog();
  }

  protected doCloseDialog(): void {
    this._store.dispatch(CancelFakturierungsbelegDialogActions.close());
  }

  private getBeleg() {
    this._store.select(CancelFakturierungsbelegDialogSelectors.cancelData).pipe(take(1))
      .subscribe(data => {
        if (data.belegId) {
          this._store.select(FakturierungsbelegEntitiesSelectors.belegById(data.belegId)).pipe(take(1))
            .subscribe(fakturierungsbelegDto => {
              this._fakturierungsbelegDto = fakturierungsbelegDto;
            });
        } else {
          this._logger.warn('cancel dialog fakturierungsbelegId is not set');
        }
      });
  }
}
