import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {InhaberEntitiesActions, mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {UmsatzsteuerService} from '../../openapi/fakturierung-openapi';
import {UmsatzsteuerschluesselEntitiesActions} from '../actions/umsatzsteuerschluessel-entities.actions';
import {of} from 'rxjs';
import {UmsatzsteuerschluesselExtended} from '../../interfaces/umsatzsteuerschluessel-extended.interface';


@Injectable()
export class UmsatzsteuerschluesselEntitiesEffects {

  private actions$ = inject(Actions);
  private logger = inject(NGXLogger);
  private snackbar = inject(MatSnackBar);
  private umsatzsteuerService = inject(UmsatzsteuerService);

  readonly setCurrentInhaberIdFromURL$ = createEffect(
    () => this.actions$.pipe(
      ofType(InhaberEntitiesActions.setCurrentInhaberIdFromURL),
      map(action => action.currentInhaberId),
      filter((currentInhaberId): currentInhaberId is string => !!currentInhaberId),
      map(currentInhaberId => UmsatzsteuerschluesselEntitiesActions.readUmsatzsteuerschluessel({betriebId: currentInhaberId})),
    ),
  );

  readonly readUmsatzsteuerschluessel$ = createEffect(
    () => this.actions$.pipe(
      ofType(UmsatzsteuerschluesselEntitiesActions.readUmsatzsteuerschluessel),
      map(action => action.betriebId),
      switchMap(betriebId => this.umsatzsteuerService.readUmsatzsteuerschluessel(betriebId).pipe(
        map(umsatzsteuerschluesselDtos => {
          this.logger.debug('read umsatzsteuerschluessel succeeded.');
          return UmsatzsteuerschluesselEntitiesActions.readUmsatzsteuerschluesselSuccess({
            betriebId,
            umsatzsteuerschluesselDtos: umsatzsteuerschluesselDtos as UmsatzsteuerschluesselExtended[],
          });
        }),
        catchError(error => of(error).pipe(
          mappedHttpErrorResponseOperator(error),
          switchMap(error => {
            this.logger.error('read umsatzsteuerschluessel failed.', 'error:', error);
            return [UmsatzsteuerschluesselEntitiesActions.readUmsatzsteuerschluesselFailed({error})];
          }),
        ))
      )),
    ),
  );

}
