import {FakturierungsbelegFormState} from '../states/fakturierungsbeleg-form.state';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {PositionMengeMode} from '../../interfaces/position-menge-modes.interface';
import {PositionEinzelpreisNettoMode} from '../../interfaces/position-einzelpreisnetto-mode.interface';


export const initialFakturierungsbelegFormState: FakturierungsbelegFormState = {
  // INFO: Grundlegende Informationen
  fakturierungsbeleg: {},
  logoUrl: null,
  positionen: [],

  // INFO: Modes
  addPositionMode: 'display',
  leistungsempfaengerMode: 'display',
  rechnungsdatumMode: 'display',
  vorlaufzeileMode: 'display',
  leistungsLieferdatumControlMode: 'display',
  leistungsLieferdatumDateMode: 'single',
  positionMengeModes: [],
  positionEinzelpreisNettoModes: [],
};

export const fakturierungsbelegFormReducer = createReducer(
  initialFakturierungsbelegFormState,

  on(FakturierungsbelegFormActions.reset, () => initialFakturierungsbelegFormState),

  on(FakturierungsbelegFormActions.updateFakturierungsbelegDto, (state, {fakturierungsbelegDto}) => ({
    ...state,
    fakturierungsbeleg: fakturierungsbelegDto,
  })),

  on(FakturierungsbelegFormActions.getLogoSuccess, (state, {logoUrl}) => ({
    ...state,
    logoUrl,
  })),

  on(FakturierungsbelegFormActions.saveLogoSuccess, (state, {logoUrl}) => ({
    ...state,
    logoUrl,
  })),

  on(FakturierungsbelegFormActions.deleteLogoSuccess, (state) => ({
    ...state,
    logoUrl: null,
  })),

  on(FakturierungsbelegFormActions.releaseLogoFromMemory, (state) => ({
    ...state,
    logoUrl: null,
  })),

  on(FakturierungsbelegFormActions.changeLeistungsempfaengerMode, (state, {mode}) => {
    return {
      ...state,
      leistungsempfaengerMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.changeVorlaufzeileMode, (state, {mode}) => {
    return {
      ...state,
      vorlaufzeileMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.fetchPositionenSuccess, (state, {positionen}) => {
    const positionMengeModes: PositionMengeMode[] = [];
    const positionEinzelpreisNettoModes: PositionEinzelpreisNettoMode[] = [];

    for (const position of positionen ?? []) {
      positionMengeModes.push({
        nummer: position.nummer,
        mode: 'display',
      });

      positionEinzelpreisNettoModes.push({
        nummer: position.nummer,
        mode: 'display',
      });
    }

    return {
      ...state,
      positionMengeModes,
      positionEinzelpreisNettoModes: positionEinzelpreisNettoModes,
      positionen: positionen ?? [],
    };
  }),

  on(FakturierungsbelegFormActions.addPositionSuccess, (state, {newlyCreatedPosition}) => {
    return {
      ...state,
      positionen: [...(state.positionen || []), newlyCreatedPosition],
    };
  }),

  on(FakturierungsbelegFormActions.deletePositionSuccess, (state, {positionId}) => {
    if (!state.positionen || state.positionen.length === 0) {
      throw new Error('Cannot delete Position in an empty or undefined array of positionen!');
    }
    const updatedPositionen = state.positionen.filter(position => position.id !== positionId);

    return {
      ...state,
      positionen: updatedPositionen,
    };
  }),

  on(FakturierungsbelegFormActions.updatePositionSuccess, (state, {newlyUpdatedPosition}) => {
    if (!state.positionen || state.positionen.length === 0) {
      throw new Error('Cannot update an empty or undefined array of positionen!');
    }

    const updatedPositionen = state.positionen.map(position =>
      position.id === newlyUpdatedPosition.id ? newlyUpdatedPosition : position
    );

    return {
      ...state,
      positionen: updatedPositionen,
    };
  }),

  on(FakturierungsbelegFormActions.changePositionMengeMode, (state, {positionMengeMode}) => {
    const positionMengeModes = [
      ...state.positionMengeModes || [],
    ];

    const positionMengeModeIndex = positionMengeModes.findIndex(item => {
      return item.nummer === positionMengeMode.nummer;
    });

    if (positionMengeModeIndex != undefined && positionMengeModeIndex !== -1) {
      positionMengeModes.splice(positionMengeModeIndex, 1);
    }

    positionMengeModes.push({
      nummer: positionMengeMode.nummer,
      mode: positionMengeMode.mode,
    });

    return {
      ...state,
      positionMengeModes,
    };
  }),

  on(FakturierungsbelegFormActions.changePositionEinzelpreisNettoMode, (state, {positionEinzelpreisNettoMode: positionEinzelpreisNettoMode}) => {
    const positionEinzelpreisNettoModes = [
      ...state.positionEinzelpreisNettoModes || [],
    ];

    const positionEinzelpreisNettoModeIndex = positionEinzelpreisNettoModes.findIndex(item => {
      return item.nummer === positionEinzelpreisNettoMode.nummer;
    });
    if (positionEinzelpreisNettoModeIndex != undefined && positionEinzelpreisNettoModeIndex !== -1) {
      positionEinzelpreisNettoModes.splice(positionEinzelpreisNettoModeIndex, 1);
    }

    positionEinzelpreisNettoModes.push({
      nummer: positionEinzelpreisNettoMode.nummer,
      mode: positionEinzelpreisNettoMode.mode,
    });

    return {
      ...state,
      positionEinzelpreisNettoModes: positionEinzelpreisNettoModes,
    };
  }),

  on(FakturierungsbelegFormActions.changeAddPositionMode, (state, {mode}) => {
    return {
      ...state,
      addPositionMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.changeRechnungsdatumMode, (state, {mode}) => {
    return {
      ...state,
      rechnungsdatumMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.changeLeistungsLieferDatumControlMode, (state, {mode}) => {
    return {
      ...state,
      leistungsLieferdatumControlMode: mode,
    };
  }),

  on(FakturierungsbelegFormActions.changeLeistungsLieferDatumDateMode, (state, {mode}) => {
    return {
      ...state,
      leistungsLieferdatumDateMode: mode,
    };
  }),

);
