import {einstellungenEntitiesAdapter} from '../adapters/einstellungen-entities.adapter';
import {createSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';


export class EinstellungenEntitiesSelectors {

  private static adapterSelectors = einstellungenEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    einstellungenEntities: (state: AppState) => state.einstellungenEntities,
  };

  public static all = createSelector(
    this.select.einstellungenEntities,
    this.adapterSelectors.selectAll,
  );

  public static byBetrieb = (betriebId: string) => createSelector(
    this.all,
    einstellungenList => einstellungenList.find(einstellungen => {
      return einstellungen.betriebId === betriebId;
    }),
  );

  public static zahlungszielTageByBetrieb = (betriebId: string) => createSelector(
    this.all,
    einstellungenList => einstellungenList.find(einstellungen => {
      return einstellungen.betriebId === betriebId;
    })?.zahlungsbedingungen?.zahlungsziel,
  );

}
