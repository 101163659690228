import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {BelegContentService, PreviewService} from 'src/app/openapi/fakturierung-openapi';
import {FakturierungsbelegContentActions} from '../actions/fakturierungsbeleg-content.actions';
import {catchError, concatMap, map, of, switchMap, take, tap} from 'rxjs';
import {PdfDownloadService} from 'src/app/services/pdf-download.service';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {FakturierungsbelegEntitiesSelectors} from '../selectors/fakturierungsbeleg-entities.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {generateDownloadFilename} from '../../helpers/generate-download-filename';
import {SentryActions} from '../actions/sentry.actions';


@Injectable()
export class FakturierungsbelegContentEffects {
  constructor(
    private _actions$: Actions,
    private _logger: NGXLogger,
    private _snackbar: MatSnackBar,
    private _belegContentService: BelegContentService,
    private _pdfDownloadService: PdfDownloadService,
    private _previewService: PreviewService,
    private _store: Store<AppState>) {
  }

  /**
   * Der Effekt wird zum Laden der Dateien der Fakturierungsbelege verwendet.
   * Der Status des Belegs wird überprüft, um festzustellen, ob es sich um einen Entwurf handelt.
   * In dem Fall wird der Preview-Service verwendet, um die Vorschau des Belegs herunterzuladen, bei einem
   * festgeschriebenen Beleg wird der Beleg-Content-Service verwendet.
   * @Param betriebId - Die ID des Betriebs
   * @Param belegId - Die ID des Belegs
   * @Param statusEntwurf - Der Status des Belegs
   */
  readonly downloadBelegContent$ = createEffect(
    () => this._actions$.pipe(
      ofType(FakturierungsbelegContentActions.downloadBelegPdf),
      concatMap((action) => {
        // INFO: "observe: 'response'" übergeben, damit der vollständige Response zurückkommt
        let downloadService$ = action.statusEntwurf
          ? this._previewService.downloadPdfPreview(action.betriebId, action.belegId, 'response')
          : this._belegContentService.downloadBelegPdfContent(action.betriebId, action.belegId, 'response');

        return this._store.select(FakturierungsbelegEntitiesSelectors.belegById(action.belegId)).pipe(
          take(1),
          switchMap(beleg => {
            if (!beleg) {
              this._logger.error(`beleg not found for id: ${action.belegId}`);
              return of(FakturierungsbelegContentActions.downloadBelegPdfFailure({
                error: 'beleg not found',
                statusEntwurf: action.statusEntwurf
              }));
            }

            this._logger.debug('successfully retrieved beleg:', beleg);

            return downloadService$.pipe(
              map(response => {
                // INFO: Auslesen des content-disposition Headers
                const contentDisposition = response.headers.get('content-disposition');
                let fileName: string;

                if (contentDisposition) {
                  // INFO: Regex, um den Dateinamen zu extrahieren
                  const matches = /filename="?([^"]+)"?/.exec(contentDisposition);
                  fileName = matches && matches[1] ? matches[1] : generateDownloadFilename(beleg);
                } else {
                  fileName = generateDownloadFilename(beleg);
                }

                // INFO: Übergabe des Response-Bodys (Blob) an den PDF-Download-Service
                if (response.body !== null) {
                  this._pdfDownloadService.downloadPDF(response.body, fileName, beleg.id, action.isPreviewMode);
                  return FakturierungsbelegContentActions.downloadBelegPdfSuccess();
                } else {
                  this._logger.error('response body is null');
                  return FakturierungsbelegContentActions.downloadBelegPdfFailure(
                    {error: 'response body is null', statusEntwurf: action.statusEntwurf}
                  );
                }
              }),
              catchError(error => of(error).pipe(
                mappedHttpErrorResponseOperator(error),
                map(error => {
                  this._logger.error('Error downloading pdf file', error);
                  return FakturierungsbelegContentActions.downloadBelegPdfFailure({
                    error,
                    statusEntwurf: action.statusEntwurf
                  });
                })
              ))
            );
          })
        );
      })
    )
  );

  readonly downloadBelegPdfFailure$ = createEffect(
    () => this._actions$.pipe(
      ofType(FakturierungsbelegContentActions.downloadBelegPdfFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 404 : {
            errorMsg = 'Der Beleg zu dieser Rechnung konnte nicht gefunden werden.';
            break;
          }
          case 422 : {
            errorMsg = 'Fehler beim Herunterladen des Belegs. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 503 : {
            errorMsg = 'Abruf des PDF vorübergehend nicht möglich. Bitte probiere es später erneut.';
            break;
          }

          default: {
            errorMsg = 'Abruf des PDF vorübergehend nicht möglich. Bitte probiere es später erneut.';
          }
        }

        this._snackbar.open(
          errorMsg,
          'Schließen',
          {duration: 5000}
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    ),
    {dispatch: false}
  );

  readonly revokeObjectUrl$ = createEffect(
    () => this._actions$.pipe(
      ofType(FakturierungsbelegContentActions.revokeObjectUrl),
      tap(action => {
        this._pdfDownloadService.revokeObjectUrl(action.objectUrl);
      }),
    ),
    {dispatch: false}
  );
}
