import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {LandDTO} from '../../openapi/fakturierung-openapi';


export class LandEntitiesActions {

  public static readLaender = createAction(
    '[Land Resolver] Read laender.',
    props<{
      betriebId: string,
    }>()
  );

  public static readLaenderSuccess = createAction(
    '[Land Entities] Read laender successfully.',
    props<{
      landDtos: LandDTO[],
    }>()
  );

  public static readLaenderFailure = createAction(
    '[Land Entities] Read laender failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>()
  );

}
