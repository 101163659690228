import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {concatMap, Observable, of, tap, withLatestFrom} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {SentryActions} from '../actions/sentry.actions';
import {FakturierungsbelegFormSelectors} from '../selectors/fakturierungsbeleg-form.selectors';
import {AppState} from '../states/app.state';
import {Store} from '@ngrx/store';
import {
  CreatePositionRequestDTO,
  NachlaufzeileService,
  PositionService, ProblemDTO,
  ProduktDTO
} from '../../openapi/fakturierung-openapi';
import {InhaberEntitiesSelectors, mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {Router} from '@angular/router';
import {FakturierungsbelegEntitiesActions} from '../actions/fakturierungsbeleg-entities.actions';


@Injectable()
export class FakturierungsbelegFormEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private httpClient: HttpClient,
    private router: Router,
    private nachlaufzeileService: NachlaufzeileService,
    private store: Store<AppState>,
    private positionService: PositionService,
  ) {
  }


  readonly handleGetFakturierungsbelegByIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegEntitiesActions.getFakturierungsbelegByIdSuccess),
      map(({belegDto}) =>
        FakturierungsbelegFormActions.updateFakturierungsbelegDto({fakturierungsbelegDto: belegDto})
      )
    )
  );

  readonly addPosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.addPosition),
      withLatestFrom(
        this.store.select(InhaberEntitiesSelectors.currentInhaberId),
        this.store.select(FakturierungsbelegFormSelectors.belegId)
      ),
      concatMap(([{produktDto}, betriebId, belegId]) => {
        if (!betriebId || !belegId) {
          this.logger.error('betriebId or belegId is missing!');
          return of(FakturierungsbelegFormActions.addPositionFailure({error: {message: 'missing ids'}}));
        }

        const createPositionRequestDTO: CreatePositionRequestDTO = this.buildCreatePositionRequestDTO(produktDto);

        return this.positionService.createPosition(betriebId, belegId, createPositionRequestDTO).pipe(
          concatMap(positionDto => {
            this.logger.debug('newly added position', positionDto);

            return [
              FakturierungsbelegFormActions.addPositionSuccess({newlyCreatedPosition: positionDto}),
              FakturierungsbelegEntitiesActions.getFakturierungsbelegById({betriebId, belegId})
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator<ProblemDTO>(error),
            map(error => {
              this.logger.error('add position failed.', 'error:', error);

              return FakturierungsbelegFormActions.addPositionFailure({error});
            }),
          )),
        );
      })
    )
  );

  readonly addPositionFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.addPositionFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 400: {
            errorMsg = action.error.error?.detail || 'Ungültige Anfrage. Position konnte nicht erstellt werden.';
            break;
          }
          case 403: {
            errorMsg = 'Fehlende Berechtigung zum Erstellen einer Position. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Beleg nicht gefunden. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice';
            break;
          }
          default: {
            errorMsg = 'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly fetchPositionen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.fetchPositionen),
      withLatestFrom(this.store.select(InhaberEntitiesSelectors.currentInhaberId)),
      concatMap(([{belegId}, betriebId]) => {
        if (!betriebId || !belegId) {
          this.logger.error('betriebId or belegId is missing!');
          return of(FakturierungsbelegFormActions.fetchPositionenFailure({error: {message: 'missing ids'}}));
        }

        return this.positionService.readPositionen(betriebId, belegId).pipe(
          map(positionenPage =>
            FakturierungsbelegFormActions.fetchPositionenSuccess({positionen: positionenPage.content})
          ),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error('fetch position failed.', 'error:', error);

              return FakturierungsbelegFormActions.fetchPositionenFailure({error});
            }),
          )),
        );
      })
    )
  );

  readonly fetchPositionenFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.fetchPositionenFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403: {
            errorMsg = 'Fehlende Berechtigung zum Anzeigen der Positionen. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404: {
            errorMsg = 'Beleg nicht gefunden. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Positionen. Bitte versuche es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly updatePosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.updatePosition),
      withLatestFrom(
        this.store.select(InhaberEntitiesSelectors.currentInhaberId),
        this.store.select(FakturierungsbelegFormSelectors.belegId)
      ),
      concatMap(([{updatePositionObj}, betriebId, belegId]) => {
        if (!betriebId || !belegId) {
          this.logger.error('betriebid or belegid is missing!');
          return of(FakturierungsbelegFormActions.updatePositionFailure({error: {message: 'missing ids'}}));
        }

        const {positionId, payload} = updatePositionObj;

        return this.positionService.updatePosition(betriebId, belegId, positionId, payload).pipe(
          concatMap(positionDto => {
            this.logger.debug('position updated', positionDto);

            return [
              FakturierungsbelegFormActions.updatePositionSuccess({newlyUpdatedPosition: positionDto}),
              FakturierungsbelegEntitiesActions.getFakturierungsbelegById({betriebId, belegId})
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator<ProblemDTO>(error),
            map(error => {
              this.logger.error('update position failed.', 'error:', error);

              return FakturierungsbelegFormActions.updatePositionFailure({error});
            }),
          )),
        );
      })
    )
  );

  readonly updatePositionFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.updatePositionFailure),
      map(action => {
        let errorMsg = '';

        switch (action.error.status) {
          case 400: {
            errorMsg = action.error.error?.detail || 'Ungültige Anfrage. Position konnte nicht aktualisiert werden.';
            break;
          }
          case 403: {
            errorMsg = 'Der Benutzer besitzt nicht das nötige Recht, um diese Position zu aktualisieren. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice';
            break;
          }
          case 404: {
            errorMsg = 'Position wurde nicht gefunden. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice';
            break;
          }
          case 422: {
            errorMsg = 'Aktualisieren der Position nicht möglich. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          default: {
            errorMsg = 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly deletePosition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.deletePosition),
      withLatestFrom(
        this.store.select(InhaberEntitiesSelectors.currentInhaberId),
        this.store.select(FakturierungsbelegFormSelectors.belegId)
      ),
      concatMap(([{positionId}, betriebId, belegId]) => {
        if (!betriebId || !belegId) {
          this.logger.error('betriebId or belegId is missing');
          return of(FakturierungsbelegFormActions.deletePositionFailure({error: {message: 'missing ids'}}));
        }

        return this.positionService.deletePosition(betriebId, belegId, positionId).pipe(
          concatMap(() => {
            this.logger.debug('position deleted', positionId);

            return [
              FakturierungsbelegFormActions.deletePositionSuccess({positionId}),
              FakturierungsbelegEntitiesActions.getFakturierungsbelegById({betriebId, belegId}),
              FakturierungsbelegFormActions.fetchPositionen({belegId}),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error('delete position failed.', 'error:', error);

              return FakturierungsbelegFormActions.deletePositionFailure({error});
            }),
          )),
        );
      })
    )
  );

  readonly deletePositionFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.deletePositionFailure),
      map(({error}) => {
        let errorMsg = '';

        switch (error.status) {
          case 403:
            errorMsg = 'Der Benutzer besitzt nicht das nötige Recht, um diese Position zu löschen. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          case 404:
            errorMsg = 'Position wurde nicht gefunden. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          case 422:
            errorMsg = 'Löschen der Position nicht möglich. Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          default:
            errorMsg = 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.';
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {errorMsg},
        });
      })
    )
  );

  readonly saveLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.saveLogo),
      concatMap(({betriebId, logoUrl}) =>
        this.httpClient.get(logoUrl, {responseType: 'blob'}).pipe(
          withLatestFrom(this.store.select(FakturierungsbelegFormSelectors.logoUrl)),
          concatMap(([blob, currentLogoUrl]) => {
            const saveOrUpdateLogo$ = currentLogoUrl ? this.updateLogo(betriebId, blob) : this.saveLogo(betriebId, blob);
            return saveOrUpdateLogo$.pipe(
              map(() => FakturierungsbelegFormActions.saveLogoSuccess({logoUrl})),
              catchError((error) =>
                of(FakturierungsbelegFormActions.saveLogoFailure({error}))
              )
            );
          })
        )
      )
    )
  );

  readonly saveLogoFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.saveLogoFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Speichern des Logos.';
            break;
          }
          case 404 : {
            errorMsg = 'Fehler beim Speichern des Logos. Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Speichern des Logos. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly getLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.getLogo),
      concatMap(({betriebId}) =>
        this.getLogo(betriebId).pipe(
          map((binaryData) => {
            const logoUrl = binaryData?.logoAsBinary ? window.URL.createObjectURL(binaryData.logoAsBinary) : null;
            return FakturierungsbelegFormActions.getLogoSuccess({logoUrl});
          }),
          catchError((error) => {
            return of(FakturierungsbelegFormActions.getLogoFailure({error}));
          })
        )
      )
    )
  );

  readonly getLogoFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.getLogoFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Abrufen des Logos.';
            break;
          }
          case 404 : {
            errorMsg = 'Fehler beim Abrufen des Logos. Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Abrufen des Logos. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly deleteLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.deleteLogo),
      concatMap(({betriebId}) =>
        this.deleteLogo(betriebId).pipe(
          map(() => {
            return FakturierungsbelegFormActions.releaseLogoFromMemory({});
          }),
          catchError(error => {
            return of(FakturierungsbelegFormActions.deleteLogoFailure({error}));
          })
        )
      )
    )
  );

  readonly deleteLogoFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.deleteLogoFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Löschen des Logos.';
            break;
          }
          case 404 : {
            errorMsg = 'Fehler beim Löschen des Logos. Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen des Logos. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly releaseLogoFromMemory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.releaseLogoFromMemory),
      withLatestFrom(this.store.select(FakturierungsbelegFormSelectors.logoUrl)),
      tap(([action, storeLogoUrl]) => {
        const logoUrl = action.logoUrl || storeLogoUrl;
        if (logoUrl) {
          window.URL.revokeObjectURL(logoUrl);
        }
      })
    ), {dispatch: false}
  );


  readonly addNachlaufzeile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.addNachlaufzeile),
      withLatestFrom(this.store.select(InhaberEntitiesSelectors.currentInhaberId)),
      concatMap(([{createNachlaufzeileRequestDTO, belegId}, inhaberId]) =>
        this.nachlaufzeileService.createNachlaufzeile(inhaberId!, belegId, createNachlaufzeileRequestDTO).pipe(
          concatMap(() => {
            return [
              FakturierungsbelegFormActions.addNachlaufzeileSuccess({belegId}),
              FakturierungsbelegEntitiesActions.getFakturierungsbelegById({
                betriebId: inhaberId != null ? inhaberId : '',
                belegId,
              }),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator<ProblemDTO>(error),
            map(mappedError => {
              this.logger.error('error while adding nachlaufzeile', mappedError, 'for betrieb-id', inhaberId, 'and beleg-id', belegId);
              return FakturierungsbelegFormActions.addNachlaufzeileFailure({error: mappedError});
            })
          ))
        )
      )
    )
  );


  readonly addNachlaufzeileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.addNachlaufzeileFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 400: {
            errorMsg = action.error.error?.detail || 'Ungültige Anfrage. Schlussbemerkung konnte nicht hinzugefügt werden.';
            break;
          }
          case 403: {
            errorMsg = 'Fehlende Berechtigung für das Hinzufügen einer Schlussbemerkung.';
            break;
          }
          case 404: {
            errorMsg = 'Fehler beim Hinzufügen einer Schlussbemerkung. Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Hinzufügen einer Schlussbemerkung. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly removeNachlaufzeile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.removeNachlaufzeile),
      withLatestFrom(this.store.select(InhaberEntitiesSelectors.currentInhaberId)),
      concatMap(([{nachlaufzeileId, belegId}, inhaberId]) =>
        this.nachlaufzeileService.deleteNachlaufzeile(inhaberId != null ? inhaberId : '', belegId, nachlaufzeileId).pipe(
          map(() => FakturierungsbelegFormActions.removeNachlaufzeileSuccess({belegId})),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(mappedError => {
              this.logger.error('error while removing schlussbemerkung', mappedError, 'for betrieb', inhaberId, 'and beleg', belegId);
              return FakturierungsbelegFormActions.removeNachlaufzeileFailure({error: mappedError});
            })
          ))
        )
      )
    )
  );

  readonly removeNachlaufzeileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.removeNachlaufzeileSuccess),
      withLatestFrom(this.store.select(InhaberEntitiesSelectors.currentInhaberId)),
      map(([{belegId}, inhaberId]) => FakturierungsbelegEntitiesActions.getFakturierungsbelegById({
        betriebId: inhaberId != null ? inhaberId : '',
        belegId
      }))
    )
  );

  readonly removeNachlaufzeileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.removeNachlaufzeileFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Löschen einer Schlussbemerkung.';
            break;
          }
          case 404 : {
            errorMsg = 'Fehler beim Löschen einer Schlussbemerkung. Inhaber nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen einer Schlussbemerkung. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  readonly updateNachlaufzeile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.updateNachlaufzeile),
      withLatestFrom(this.store.select(InhaberEntitiesSelectors.currentInhaberId)),
      concatMap(([{nachlaufzeileId, updateNachlaufzeileRequestDTO, belegId}, inhaberId]) =>
        this.nachlaufzeileService.updateNachlaufzeile(inhaberId != null ? inhaberId : '', belegId, nachlaufzeileId, updateNachlaufzeileRequestDTO).pipe(
          map(() => FakturierungsbelegFormActions.updateNachlaufzeileSuccess({belegId})),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(mappedError => {
              this.logger.error('error while updating nachlaufzeile', mappedError, 'for betrieb', inhaberId, 'and beleg', belegId);
              return FakturierungsbelegFormActions.updateNachlaufzeileFailure({error: mappedError});
            })
          ))
        )
      )
    )
  );

  readonly updateNachlaufzeileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.updateNachlaufzeileSuccess),
      withLatestFrom(this.store.select(InhaberEntitiesSelectors.currentInhaberId)),
      map(([{belegId}, inhaberId]) => FakturierungsbelegEntitiesActions.getFakturierungsbelegById({
        betriebId: inhaberId != null ? inhaberId : '',
        belegId
      }))
    )
  );

  readonly updateNachlaufzeileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.updateNachlaufzeileFailure),
      map(action => {
        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Aktualisieren einer Schlussbemerkung.';
            break;
          }
          case 404 : {
            errorMsg = 'Fehler beim Aktualisieren einer Schlussbemerkung. Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren einer Schlussbemerkung. Bitte probiere es später erneut.';
          }
        }
        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
        return SentryActions.captureException({
          error: action.error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );


  /**
   * Effekt zum weiterleiten auf die Fakturierungsbeleg-Details-Seite nach dem erstellen eines neuen Initialen Belegs:
   * /fakturierungsbelege/inhaber/<inhaberId>/edit/fakturierungsbeleg/<belegId>
   */
  readonly navigateToNewBeleg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FakturierungsbelegFormActions.redirectToForm),
      tap(({belegId, betriebId}) => {
        this.router.navigate([`/fakturierungsbelege/inhaber/${betriebId}/edit/fakturierungsbeleg/${belegId}`]);
      })
    ), {dispatch: false},
  );


  // INFO : Placeholder method, until the backend is implemented.
  private saveLogo(inhaberId: string, body: Blob): Observable<boolean> {
    if (inhaberId === null || inhaberId === undefined) {
      throw new Error('Required parameter inhaberId was null or undefined when calling saveLogo.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling saveLogo.');
    }

    console.log('Temporary solution: Simulating successful save of logo.');
    return of(true);  // Simulate successful response
  }

  // INFO : Placeholder method, until the backend is implemented.
  private updateLogo(inhaberId: string, body: Blob): Observable<boolean> {
    if (inhaberId === null || inhaberId === undefined) {
      throw new Error('Required parameter inhaberId was null or undefined when calling updateLogo.');
    }
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateLogo.');
    }

    console.log('Temporary solution: Simulating successful update of logo.');
    return of(true);  // Simulate successful response
  }

  // INFO : Placeholder method, until the backend is implemented.
  private deleteLogo(inhaberId: string): Observable<void> {
    if (!inhaberId) {
      throw new Error('Required parameter inhaberId was null or undefined when calling deleteLogo.');
    }

    console.log('Temporary solution: Simulating successful logo deletion.');
    return of(undefined);
  }

  // INFO : Placeholder method, until the backend is implemented.
  private getLogo(inhaberId: string): Observable<any> {
    if (!inhaberId) {
      throw new Error('Required parameter inhaberId was null or undefined when calling getLogo.');
    }

    console.log('Temporary solution: Simulating successful logo fetching.');
    return of(null);
  }

  private buildCreatePositionRequestDTO(produktDto: ProduktDTO): CreatePositionRequestDTO {
    let einzelpreisNetto = 0;
    if (produktDto.berechnungsregel && 'betrag' in produktDto.berechnungsregel && typeof produktDto.berechnungsregel.betrag === 'number') {
      einzelpreisNetto = produktDto.berechnungsregel.betrag;
    }

    return {
      produktId: produktDto.id,
      menge: 1,
      einzelpreisNetto: einzelpreisNetto,
    };
  }

}
