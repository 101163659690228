import {createAction, props} from '@ngrx/store';
import {EinstellungenRequestDTO, EinstellungenResponseDTO} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


/**
 * Actions für den Entity Store der Standardeinstellungen.
 * Diese Actions dienen dazu, die Einstellungen eines bestimmten Betriebs (identifiziert durch betriebId)
 * zu laden und zu aktualisieren.
 */
export class EinstellungenEntitiesActions {

  private static readonly prefix = '[Einstellungen Entities] ';

  /**
   * Löst den Ladevorgang der Standardeinstellungen für einen Betrieb aus.
   *
   * @property {string} betriebId - Die ID des Betriebs, dessen Einstellungen geladen werden sollen.
   */
  public static getEinstellungenDefault = createAction(
    this.prefix + 'Read default settings.',
    props<{
      betriebId: string,
    }>(),
  );

  /**
   * Wird dispatcht, wenn der GET-Request der Standardeinstellungen erfolgreich war.
   *
   * @property {string} betriebId - Die ID des Betriebs, dessen Einstellungen geladen wurden.
   * @property {EinstellungenResponseDTO} einstellungen - Das geladene Einstellungen-Objekt.
   */
  public static getEinstellungenDefaultSuccess = createAction(
    this.prefix + 'Read default settings success.',
    props<{
      betriebId: string,
      einstellungen: EinstellungenResponseDTO,
    }>(),
  );

  /**
   * Wird dispatcht, wenn der GET-Request der Standardeinstellungen fehlschlägt.
   *
   * @property {string} betriebId - Die ID des Betriebs, bei dem der GET-Request fehlgeschlagen ist.
   * @property {MappedHttpErrorResponse} error - Der aufgetretene Fehler im serialisierten Format.
   */
  public static getEinstellungenDefaultFailure = createAction(
    this.prefix + 'Read default settings failure.',
    props<{
      betriebId: string,
      error: MappedHttpErrorResponse,
    }>(),
  );

  /**
   * Löst den Update-Vorgang der Standardeinstellungen für einen Betrieb aus.
   *
   * @property {string} betriebId - Die ID des Betriebs, dessen Einstellungen aktualisiert werden sollen.
   * @property {EinstellungenRequestDTO} einstellungen - Die neuen Einstellungen-Daten, die übernommen werden sollen.
   */
  public static updateEinstellungenDefault = createAction(
    this.prefix + 'Update default settings.',
    props<{
      betriebId: string,
      einstellungen: EinstellungenRequestDTO,
    }>(),
  );

  /**
   * Wird dispatcht, wenn der UPDATE-Request der Standardeinstellungen erfolgreich war.
   *
   * @property {string} betriebId - Die ID des Betriebs, dessen Einstellungen aktualisiert wurden.
   * @property {EinstellungenResponseDTO} einstellungen - Das aktualisierte Einstellungen-Objekt.
   */
  public static updateEinstellungenDefaultSuccess = createAction(
    this.prefix + 'Update default settings success.',
    props<{
      betriebId: string,
      einstellungen: EinstellungenResponseDTO,
    }>(),
  );

  /**
   * Wird dispatcht, wenn der UPDATE-Request der Standardeinstellungen fehlschlägt.
   *
   * @property {string} betriebId - Die ID des Betriebs, bei dem der Update-Vorgang fehlgeschlagen ist.
   * @property {MappedHttpErrorResponse} error - Der aufgetretene Fehler im serialisierten Format.
   */
  public static updateEinstellungenDefaultFailure = createAction(
    this.prefix + 'Update default settings failure.',
    props<{
      betriebId: string,
      error: MappedHttpErrorResponse,
    }>(),
  );
}
