import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {LandEntitiesActions} from '../actions/land-entities.actions';
import {concatMap, of, switchMap} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {InhaberEntitiesActions, mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {SentryActions} from '../actions/sentry.actions';
import {LandService} from '../../openapi/fakturierung-openapi';
import {MengeneinheitEntitiesActions} from '../actions/mengeneinheit-entities.actions';


@Injectable()
export class LandEntitiesEffects {

  constructor(
    private actions$: Actions,
    private landService: LandService,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  readonly setCurrentInhaberIdFromURL$ = createEffect(
    () => this.actions$.pipe(
      ofType(InhaberEntitiesActions.setCurrentInhaberIdFromURL),
      map(action => action.currentInhaberId),
      filter((currentInhaberId): currentInhaberId is string => !!currentInhaberId),
      map(currentInhaberId => LandEntitiesActions.readLaender({betriebId: currentInhaberId})),
    ),
  );

  /**
   * Lädt die Länder vom Service.
   */
  readonly readLaender$ = createEffect(
    () => this.actions$.pipe(
      ofType(LandEntitiesActions.readLaender),
      switchMap((props) => {
        return this.landService.readLaender(props.betriebId).pipe(
          map(landDtos => {
            this.logger.debug(
              'read laender succeeded.',
            );

            return LandEntitiesActions.readLaenderSuccess({
              landDtos,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            concatMap(error => {
              this.logger.error(
                'read laender failed. error: ',
                error,
              );

              return [
                LandEntitiesActions.readLaenderFailure({
                  error: error,
                }),
                SentryActions.captureException({
                  error,
                  extras: {}
                }),
              ];
            }),
          ))
        );
      }),
    ),
  );

  /**
   * Error-Handling für das Laden der Länder.
   */
  readonly readLaenderFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(LandEntitiesActions.readLaenderFailure),
      map(({error}) => {
        this.snackbar.open(
          'Fehler bei dem Laden der Länder-Informationen. Bitte probiere es später erneut.',
          undefined,
          {
            duration: 5000,
          });
      }),
    ),
    {dispatch: false},
  );

}
