import {ButtonVariant, InhaberEntitiesSelectors, RouterSelectors} from '@adnova/jf-ng-components';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subject, takeUntil} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {NavigationLink} from './navigation-link.interface';
import {IconName} from '@adnova/jf-ng-components';
import {AppNavigationSelectors} from './+store/app-navigation.selectors';


@Component({
  selector: 'app-navigation',
  templateUrl: './app-navigation.component.html',
  styleUrls: ['./app-navigation.component.scss']
})
export class AppNavigationComponent implements OnInit, OnDestroy {

  private readonly _destroyed$ = new Subject<void>();

  private _buttonAction?: Action;

  private _buttonLabel = '';

  private _buttonIcon?: IconName;

  private _buttonVariant: ButtonVariant;

  private _buttonEnabled = false;

  private _navigationLinks: NavigationLink[] = [];

  protected inhaberId?: string;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  get buttonAction(): Action | undefined {
    return this._buttonAction;
  }

  set buttonAction(value: Action | undefined) {
    this._buttonAction = value;
  }

  get buttonLabel(): string {
    return this._buttonLabel;
  }

  set buttonLabel(value: string) {
    this._buttonLabel = value;
  }

  get buttonIcon(): IconName | undefined {
    return this._buttonIcon;
  }

  set buttonIcon(value: IconName | undefined) {
    this._buttonIcon = value;
  }

  get buttonVariant(): ButtonVariant {
    return this._buttonVariant;
  }

  set buttonVariant(value: ButtonVariant) {
    this._buttonVariant = value;
  }

  get buttonEnabled(): boolean {
    return this._buttonEnabled;
  }

  set buttonEnabled(value: boolean) {
    this._buttonEnabled = value;
  }

  get navigationLinks(): NavigationLink[] {
    return this._navigationLinks;
  }

  set navigationLinks(value: NavigationLink[]) {
    this._navigationLinks = value;
  }

  ngOnInit(): void {

    this.store.select(AppNavigationSelectors.buttonEnabled).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonEnabled) => {
      this.buttonEnabled = buttonEnabled;
    });

    this.store.select(RouterSelectors.dataByKey('buttonLabel')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonLabel) => {
      this.buttonLabel = buttonLabel || '';
    });

    this.store.select(RouterSelectors.dataByKey<Action>('buttonAction')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonAction) => {
      this.buttonAction = buttonAction;
    });

    this.store.select(RouterSelectors.dataByKey<IconName>('buttonIcon')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonIcon) => {
      this.buttonIcon = buttonIcon;
    });

    this.store.select(RouterSelectors.dataByKey<ButtonVariant>('buttonVariant')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((buttonVariant) => {
      this.buttonVariant = buttonVariant;
    });

    this.store.select(RouterSelectors.dataByKey<NavigationLink[]>('navigationLinks')).pipe(
      takeUntil(this._destroyed$),
    ).subscribe((navigationLinks) => {
      if (navigationLinks) {
        this.navigationLinks = navigationLinks;
      } else {
        this.navigationLinks = [];
      }
    });

    combineLatest([
      this.store.select(InhaberEntitiesSelectors.currentInhaberId),
      this.store.select(InhaberEntitiesSelectors.refInhaberId),
      this.store.select(InhaberEntitiesSelectors.fullInhaberList),
    ]).pipe(
      takeUntil(this._destroyed$),
    ).subscribe(([currentInhaberId, refInhaberId, fullInhaberList]) => {
      if (currentInhaberId) {
        this.inhaberId = currentInhaberId;
      } else if (refInhaberId) {
        this.inhaberId = refInhaberId;
      } else if (fullInhaberList.length > 0) {
        this.inhaberId = fullInhaberList[0].id;
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  doButtonAction(): void {
    if (this._buttonAction) {
      this.store.dispatch(this._buttonAction);
    }
  }

}
