import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {debounceTime, take} from 'rxjs';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {KundenFormControls} from './kunden-form.controls';
import {
  RadioButton
} from '@adnova/jf-ng-components/lib/generic/form-elements/form-fields/radio/radio-button/radio-button.interface';
import {KundeDialogActions} from '../../../store/actions/kunde-dialog.actions';
import {KundePrivatpersonFormControls} from './kunde-privatperson-form/kunde-privatperson-form.controls';
import {KundeDialogSelectors} from '../../../store/selectors/kunde-dialog.selectors';
import {
  AdresseRequestDTO,
  BelegDTO,
  GeschaeftskundendatenDTO,
  KundeDTO,
  KundeRequestDTO,
  LandDTO,
  PrivatkundendatenDTO,
} from '../../../openapi/fakturierung-openapi';
import {FormActionButtons, InhaberEntitiesSelectors} from '@adnova/jf-ng-components';
import {KundeEntitiesSelectors} from '../../../store/selectors/kunde-entities.selectors';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {KundeEntitiesActions} from '../../../store/actions/kunde-entities.actions';
import {LandEntitiesSelectors} from '../../../store/selectors/land-entities.selectors';
import {filter} from 'rxjs/operators';
import {KundeGeschaeftskundeFormControls,} from './kunde-geschaeftskunde-form/kunde-geschaeftskunde-form.interface';
import {DeepPartial} from '../../../types/deep-partial';
import {FormType} from '../../../interfaces/form.type';
import {FakturierungsbelegFormSelectors} from '../../../store/selectors/fakturierungsbeleg-form.selectors';
import {checkVAT, countries, Country} from 'jsvat';
import Decimal from 'decimal.js';


@Component({
  selector: 'app-kunde-dialog',
  templateUrl: './kunde-dialog.component.html',
  styleUrls: ['./kunde-dialog.component.scss']
})
export class KundeDialogComponent implements OnInit {

  private _store = inject(Store<AppState>);
  private _destroyRef = inject(DestroyRef);
  private _addToInvoice: boolean = false;
  private _laender: LandDTO[] = [];
  private _anreden: string[] = [
    '-',
    'Frau',
    'Herr',
  ];
  private _selectedAbsenderType?: string;
  private _kundenFormType = FormType.CREATE;
  private _primaryButtonLabel = 'Kontakt anlegen';
  private _kundeDto?: DeepPartial<KundeDTO>;
  protected title = 'Neuen Kontakt anlegen';

  private _formControlsPrivat: KundePrivatpersonFormControls = {
    anrede: new FormControl(null),
    titel: new FormControl(''),
    kundennummer: new FormControl<Decimal | null>(null, [
      Validators.required,
      Validators.min(10000),
    ]),
    vorname: new FormControl('', [
      Validators.required,
    ]),
    nachname: new FormControl('', [
      Validators.required,
    ]),
    strasseHausnummer: new FormControl('', [
      Validators.required,
    ]),
    plz: new FormControl(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern(/^\d+$/),
    ]),
    ort: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-ZäöüÄÖÜß\s\d-]+$/),
    ]),
    landPrivat: new FormControl(null, [
      Validators.required,
    ]),
    email: new FormControl('', [
      Validators.email,
      Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i)],
    ),
  };

  private _formControlsGeschaeft: KundeGeschaeftskundeFormControls = {
    firma: new FormControl('', [
      Validators.required,
    ]),
    kundennummer: new FormControl<Decimal | null>(null, [
      Validators.required,
      Validators.min(10000),
    ]),
    strasseHausnummer: new FormControl('', [
      Validators.required,
    ]),
    plz: new FormControl(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern(/^\d+$/),
    ]),
    ort: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-ZäöüÄÖÜß\s\d-]+$/),
    ]),
    landGeschaeft: new FormControl(null, [
      Validators.required,
    ]),
    ustId: new FormControl('', [this.vatIdValidator()]),
    email: new FormControl('', [
      Validators.email,
      Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i),
    ]),
    ansprechpartnerAnrede: new FormControl(null),
    ansprechpartnerTitel: new FormControl(''),
    ansprechpartnerVorname: new FormControl(''),
    ansprechpartnerNachname: new FormControl(''),
  };

  private _kundendatenTypes: RadioButton[] = [
    {
      label: 'Geschäftskunde',
      value: 'Geschaeftskundendaten',
      id: 'Geschaeftskundendaten',
      isSelected: true,
    },
    {
      label: 'Privatperson',
      value: 'Privatkundendaten',
      id: 'Privatkundendaten',
      isSelected: false,
    },
  ];

  private _formControls: KundenFormControls = {
    kundendatenType: new FormControl(null),
    privat: new FormGroup(this._formControlsPrivat),
    geschaeft: new FormGroup(this._formControlsGeschaeft),
  };
  private _formGroup = new FormGroup(this._formControls);
  private _betriebId = '';

  protected formActionButtons: FormActionButtons = {
    primaryButton: {
      label: this._primaryButtonLabel,
      action: () => {
        this.doConfirmClicked();
      },
    },
    secondaryButtons: [
      {
        label: 'Abbrechen',
        action: () => {
          this.closeDialogClick();
        },
      },
    ],
  };

  get laender(): LandDTO[] {
    return this._laender;
  }

  get anreden(): string[] {
    return this._anreden;
  }

  get selectedAbsenderType(): string | undefined {
    return this._selectedAbsenderType;
  }

  get primaryButtonLabel(): string {
    return this._primaryButtonLabel;
  }

  get kundeDto(): DeepPartial<KundeDTO> | undefined {
    return this._kundeDto;
  }

  get kundendatenTypes(): RadioButton[] {
    return this._kundendatenTypes;
  }

  get formControls(): KundenFormControls {
    return this._formControls;
  }

  kundeChanged(kunde: DeepPartial<KundeDTO>): void {
    this._store.dispatch(KundeDialogActions.changeKunde({kunde}));
  }

  ngOnInit() {

    // INFO: Ermitteln der nächsten freien Kundennummer.
    this._store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
      takeUntilDestroyed(this._destroyRef),
      filter((betriebId): betriebId is string => !!betriebId),
    ).subscribe(betriebId => {
      this._store.dispatch(KundeEntitiesActions.detectNextKundennummer({betriebId}));
    });

    // INFO: Setzen der aktuellen BetriebId.
    this._store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
      take(1),
    ).subscribe(inhaberId => {
      if (!inhaberId) return;
      this._betriebId = inhaberId;
    });

    // INFO: Länder aus dem Store laden.
    this._store.select(LandEntitiesSelectors.all).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(landDtos => {
      this._laender = landDtos;
    });


    // INFO: Handling für das Befüllen der Controls
    this._store.select(KundeDialogSelectors.kunde).pipe(
      take(1),
    ).subscribe(kunde => {
      if (!kunde) return;

      if (kunde.id) {
        this.title = 'Kontakt bearbeiten';

        if (this.formActionButtons.primaryButton) {
          this.formActionButtons.primaryButton.label = 'Speichern';
        }
      }
      this._kundenFormType = FormType.EDIT;

      // INFO: Beide Formale werden mit den Daten befüllt.
      if (kunde.kundendaten?.typ) {
        this._kundendatenTypes.map(kundendatenType => {
          kundendatenType.isSelected = kundendatenType.id === kunde.kundendaten?.typ;
        });
      }

    });

    this._selectedAbsenderType = this._kundendatenTypes.find(type => type.isSelected)?.value;

    this.formControls.kundendatenType.valueChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(value => {
      if (!value || !value.value) return;

      if (this._selectedAbsenderType === 'Privatkundendaten' && value.value === 'Geschaeftskundendaten') {
        this.convertToGeschaeftskunde();
      } else if (this._selectedAbsenderType === 'Geschaeftskundendaten' && value.value === 'Privatkundendaten') {
        this.convertToPrivatkunde();
      } else {
        this.loadKunde();
      }

      this._store.select(InhaberEntitiesSelectors.currentInhaberId).pipe(
        filter((betriebId): betriebId is string => !!betriebId),
        take(1),
      ).subscribe(betriebId => {
        this._store.select(KundeEntitiesSelectors.nextKundennummerByBetriebId(betriebId)).pipe(
          filter((nextKundennummer): nextKundennummer is number => !!nextKundennummer),
          take(1),
        ).subscribe(nextKundennummer => {
          /*
           * INFO: Prüfung muss auf undefined sein, damit die Kundennummer nicht überschrieben wird, wenn sie "0" sein sollte.
           * Das kommt in der praxis zwar eigentlich nicht vor, aber könnte u.U. debugging erschweren, wenn es doch der Fall
           * sein sollte.
           */
          if (this._kundeDto?.kundennummer !== undefined) {
            this.setNextKundennummer(this._kundeDto.kundennummer);
          } else {
            this.setNextKundennummer(nextKundennummer);
          }
        });
      });

      this._selectedAbsenderType = value.value;
    });

    // INFO: Setzen des Kennzeichens, ob der Kunde zur Rechnung hinzugefügt werden soll.
    this._store.select(KundeDialogSelectors.addToInvoice).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(addToInvoice => {
      this._addToInvoice = addToInvoice || false;
    });

    // INFO: Schließen des Dialogs, wenn der Erstell- oder Speicher-Vorgang erfolgreich war.
    this._store.select(KundeEntitiesSelectors.createSaveActionSuccessful).pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(successful => {
      if (!successful) return;
      this._store.dispatch(KundeDialogActions.close());
    });

    // INFO: Im MVP kann das Land nicht geändert werden. Aus diesem Grund ist die folgende Subscription nicht notwendig.
    this._formControlsGeschaeft.landGeschaeft.disable();
    this._formControlsPrivat.landPrivat.disable();

    // INFO: Prüfen, ob die USt-IdNr. Pflichtfeld ist.
    // this._formControls.geschaeft.get('landGeschaeft')?.valueChanges.pipe(
    //   takeUntilDestroyed(this._destroyRef),
    //   map(value => value as FormFieldSelectValue),
    // ).subscribe(value => {
    //   // INFO: Die Id der gewählten Option entspricht der Bezeichnung des Landes.
    //   const id = value.selectedOptionValueIds?.[0] || '';
    //
    //   // INFO: Sofern das gewählte Land nicht Deutschland ist, so muss die USt-IdNr. Pflichtfeld sein.
    //   const ustIdFormField = this._formControls.geschaeft.get('ustId');
    //   if (id === 'Deutschland') {
    //     ustIdFormField?.setValidators([Validators.pattern('^[A-Z]{2}\\d{8,12}$')]);
    //   } else {
    //     ustIdFormField?.setValidators([Validators.required, Validators.pattern('^[A-Z]{2}\\d{8,12}$')]);
    //   }
    //   ustIdFormField?.updateValueAndValidity();
    // });

    this._formGroup.statusChanges.pipe(
      takeUntilDestroyed(this._destroyRef),
    ).subscribe(() => {
      if (this.formActionButtons.primaryButton) {
        this.formActionButtons.primaryButton.disabled = !this.checkFormComplete();
      }
    });
  }

  // INFO: Absenden des Formulars.
  doConfirmClicked(): void {
    const kundeRequestDto: KundeRequestDTO = this.createKundeRequestDto();
    if (this.kundeDto?.id) {
      this._store.dispatch(KundeEntitiesActions.updateKunde({
        betriebId: this._betriebId,
        kundeId: this.kundeDto.id,
        requestDto: kundeRequestDto,
      }));
    } else {

      if (this._addToInvoice) {
        // INFO: Beleg aus dem Store laden und an die Aktion übergeben, um den Kunden zur Rechnung hinzuzufügen.
        this._store.select(FakturierungsbelegFormSelectors.fakturierungsbeleg).pipe(
          take(1),
        ).subscribe(fakturierungsbeleg => {
          this._store.dispatch(KundeEntitiesActions.createKunde({
            betriebId: this._betriebId!,
            requestDto: kundeRequestDto,
            addToInvoice: this._addToInvoice,
            beleg: fakturierungsbeleg as BelegDTO,
          }));
        });

      } else {
        // INFO: Andernfalls wird der Kunde erstellt aber keinem Beleg hinzugefügt.
        this._store.dispatch(KundeEntitiesActions.createKunde({
          betriebId: this._betriebId!,
          requestDto: kundeRequestDto,
          addToInvoice: this._addToInvoice,
          beleg: undefined,
        }));
      }
    }
  }

  /**
   * Erstellt ein CreateKundeRequestDTO aus den Formularwerten.
   * Aktuell ist das CreateKundeRequestDTO sowie das UpdateKundeRequestDTO identisch.
   * Das ist aktuell OK und kann sich zukünftig ändern.
   *
   * @private
   */
  private createKundeRequestDto(): KundeRequestDTO {
    if (this._selectedAbsenderType === 'Privatkundendaten') {
      const formControlsPrivat = this._formControlsPrivat;

      const strasseHausnummer = formControlsPrivat.strasseHausnummer.value || '';
      const postleitzahl = formControlsPrivat.plz.value || '';
      const ort = formControlsPrivat.ort.value || '';

      const landIsoAlpha2 = formControlsPrivat.landPrivat.value?.selectedOptionValueIds?.at(0) || '';
      const adresseDto: AdresseRequestDTO = {
        strasseHausnummer,
        postleitzahl,
        ort,
        landIsoAlpha2,
      };

      const anrede = formControlsPrivat.anrede.value?.selectedOptionValueIds?.at(0) === '-'
        ? undefined
        : formControlsPrivat.anrede.value?.selectedOptionValueIds?.at(0);

      const titel = formControlsPrivat.titel.value || undefined;
      const vorname = formControlsPrivat.vorname.value || '';
      const nachname = formControlsPrivat.nachname.value || '';
      const emailAdresse = formControlsPrivat.email.value || undefined;

      const privatkundendatenDto: PrivatkundendatenDTO = {
        anrede,
        titel,
        vorname,
        nachname,
        emailAdresse,
        typ: 'Privatkundendaten',
      };

      const kundennummer = formControlsPrivat.kundennummer.value?.toNumber() || 0;

      return {
        kundennummer,
        adresse: adresseDto,
        kundendaten: privatkundendatenDto,
      };

    } else {
      const formControlsGeschaeft = this._formControlsGeschaeft;

      const strasseHausnummer = formControlsGeschaeft.strasseHausnummer.value || '';
      const postleitzahl = formControlsGeschaeft.plz.value || '';
      const ort = formControlsGeschaeft.ort.value || '';
      const landIsoAlpha2 = formControlsGeschaeft.landGeschaeft.value?.selectedOptionValueIds?.at(0) || '';

      const adresseDto: AdresseRequestDTO = {
        strasseHausnummer,
        postleitzahl,
        ort,
        landIsoAlpha2,
      };

      const firmenbezeichnung = formControlsGeschaeft.firma.value || '';
      const ustIdNummer = formControlsGeschaeft.ustId.value || undefined;
      const emailAdresse = formControlsGeschaeft.email.value || undefined;

      const anrede = formControlsGeschaeft.ansprechpartnerAnrede.value?.selectedOptionValueIds?.at(0);

      const titel = formControlsGeschaeft.ansprechpartnerTitel.value || undefined;
      const vorname = formControlsGeschaeft.ansprechpartnerVorname.value || undefined;
      const nachname = formControlsGeschaeft.ansprechpartnerNachname.value || undefined;

      const geschaeftskundendatenDto: GeschaeftskundendatenDTO = {
        firmenbezeichnung,
        ustIdNummer,
        emailAdresse,
        ansprechpartner: {
          anrede,
          titel,
          vorname,
          nachname,
        },
        typ: 'Geschaeftskundendaten',
      };

      const kundennummer = formControlsGeschaeft.kundennummer.value?.toNumber() || 0;

      return {
        kundennummer,
        adresse: adresseDto,
        kundendaten: geschaeftskundendatenDto,
      };
    }
  }

  protected closeDialogClick(): void {
    this._store.dispatch(KundeDialogActions.close());
  };

  /**
   * Überprüft, ob das Formular vollständig ist. Wenn folgende Bedingungen erfüllt sind, wird true zurückgegeben:
   * 1. Es muss geprüft werden, ob der ausgewählte Absender-Typ verändert worden ist.
   * 2. Es muss geprüft werden, ob die Werte des Privatpersonen-Formulars verändert wurden und valide sind.
   * 3. Es muss geprüft werden, ob die Werte des Geschäftskunden-Formulars verändert wurden und valide sind.
   *
   * @protected
   */
  protected checkFormComplete() {
    const isComplete =
      (this._selectedAbsenderType === 'Privatkundendaten'
        && this._formGroup.controls.privat.valid
        && this._formGroup.controls.privat.dirty)
      || (this._selectedAbsenderType === 'Geschaeftskundendaten'
        && this._formGroup.controls.geschaeft.valid
        && this._formGroup.controls.geschaeft.dirty);
    return isComplete;
  }

  private loadKunde(): void {
    this._store.select(KundeDialogSelectors.kunde).pipe(
      debounceTime(0), // INFO: Wird benötigt, um synchronisationsfehler zu vermeiden.
      take(1),
    ).subscribe(kunde => {
      if (!kunde) return;
      this._kundeDto = kunde;
      this.kundeChanged(this._kundeDto);
    });
  }

  private convertToGeschaeftskunde(): void {
    this._store.select(KundeDialogSelectors.kunde).pipe(
      take(1),
    ).subscribe(kunde => {
      if (!kunde) return;
      this._kundeDto = this.kundeToGeschaeftskunde(kunde);
      this.kundeChanged(this._kundeDto);
    });
  }

  private convertToPrivatkunde(): void {
    this._store.select(KundeDialogSelectors.kunde).pipe(
      take(1),
    ).subscribe(kunde => {
      if (!kunde) return;
      this._kundeDto = this.kundeToPrivatkunde(kunde);
      this.kundeChanged(this._kundeDto);
    });
  }

  private kundeToGeschaeftskunde(kunde: DeepPartial<KundeDTO>): DeepPartial<KundeDTO> {
    if (kunde?.kundendaten && !('ansprechpartner' in kunde.kundendaten)) {
      const privatkundendaten: PrivatkundendatenDTO = kunde.kundendaten as PrivatkundendatenDTO;
      const geschaeftskundendaten: GeschaeftskundendatenDTO = {
        firmenbezeichnung: '',
        emailAdresse: privatkundendaten.emailAdresse,
        ansprechpartner: {
          anrede: privatkundendaten.anrede,
          titel: privatkundendaten.titel,
          vorname: privatkundendaten.vorname,
          nachname: privatkundendaten.nachname,
        },
        typ: 'Geschaeftskundendaten',
      };

      return {
        ...kunde,
        kundendaten: geschaeftskundendaten,
      };
    }
    return kunde;
  }

  private kundeToPrivatkunde(kunde: DeepPartial<KundeDTO>): DeepPartial<KundeDTO> {
    if (kunde?.kundendaten && 'ansprechpartner' in kunde.kundendaten) {
      const geschaeftskundendaten: GeschaeftskundendatenDTO = kunde.kundendaten as GeschaeftskundendatenDTO;
      const privatkundendaten: PrivatkundendatenDTO = {
        anrede: geschaeftskundendaten.ansprechpartner?.anrede,
        titel: geschaeftskundendaten.ansprechpartner?.titel,
        vorname: geschaeftskundendaten.ansprechpartner?.vorname || '',
        nachname: geschaeftskundendaten.ansprechpartner?.nachname || '',
        emailAdresse: geschaeftskundendaten.emailAdresse,
        typ: 'Privatkundendaten',
      };

      return {
        ...kunde,
        kundendaten: privatkundendaten,
      };
    }
    return kunde;
  }

  private setNextKundennummer(nextKundennummer?: number): void {
    nextKundennummer = nextKundennummer === undefined ? 10000 : nextKundennummer;
    this._kundeDto = {
      ...this._kundeDto,
      kundennummer: nextKundennummer,
    };
    this._store.dispatch(KundeDialogActions.assignNextKundennummer({nummer: nextKundennummer}));
  }

  private vatIdValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const slovakia: Country = {
        name: 'Slovakia',
        codes: ['SK'],
        calcFn: () => true,
        rules: {
          multipliers: {},
          regex: [/^SK\s*\d{10}$/],
        },
      };

      const sanMarino: Country = {
        name: 'San Marino',
        codes: ['SM'],
        calcFn: () => true,
        rules: {
          multipliers: {},
          regex: [/^SM\s*\d{5}$/],
        },
      };

      const northernIreland: Country = {
        name: 'Northern Ireland',
        codes: ['XI'],
        calcFn: () => true,
        rules: {
          multipliers: {},
          regex: [/^XI\s*(?:\d{9}|\d{12}|(?:GD|HA)\d{3})$/],
        },
      };

      const extendedCountries = [...countries, slovakia, sanMarino, northernIreland];
      const vatCheckResult = checkVAT(control.value, extendedCountries);

      if (!vatCheckResult.isValidFormat) {
        return {invalidVatFormat: true};
      }

      if (!vatCheckResult.isValid) {
        return {invalidVatId: vatCheckResult};
      }

      return null;
    };
  }

}

