import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import {AppState} from '../states/app.state';
import {
  fakturierungsbelegEntitiesReducer,
  initialFakturierungsbelegEntitiesState
} from './fakturierungsbeleg-entities.reducers';
import {initialKundeEntitiesState, kundeEntitiesReducer} from './kunde-entities.reducers';
import {initialProduktEntitiesState, produktEntitiesReducer} from './produkt-entities.reducers';
import {fakturierungsbelegTableReducer, initialFakturierungsbelegTableState} from './fakturierungsbeleg-table.reducer';
import {
  deleteFakturierungsbelegDialogReducer,
  initialDeleteFakturierungsbelegDialogState
} from './delete-fakturierungsbeleg-dialog.reducers';
import {deleteAbsenderDialogReducer, initialDeleteKundeDialogState} from './delete-kunde-dialog.reducers';
import {initialJfLibState, jfLibReducers} from '@adnova/jf-ng-components';
import {belegeEmptyStateDialogReducer, initialBelegeEmptyStateDialogState} from './belege-empty-state-dialog.reducers';
import {initialKundeTableState, kundeTableReducer} from './kunde-table.reducer';
import {initialProduktTableState, produktTableReducer} from './produkt-table.reducer';
import {deleteProduktDialogReducer, initialDeleteProduktDialogState} from './delete-produkt-dialog.reducers';
import {initialUploadLogoDialogState, uploadLogoDialogReducer} from './upload-logo-dialog.reducers';
import {initialProduktDialogState, produktDialogReducer} from './produkt-dialog.reducer';
import {initialKundeDialogState, kundeDialogReducer} from './kunde-dialog.reducers';
import {
  initialKundeLeistungsempfaengerState,
  kundeLeistungsempfaengerReducer
} from './kunde-leistungsempfaenger.reducers';
import {fakturierungsbelegFormReducer, initialFakturierungsbelegFormState} from './fakturierungsbeleg-form.reducers';
import {addPositionReducer, initialAddPositionState} from './add-position.reducers';
import {initialLandEntitiesState, landEntitiesReducer} from './land-entities.reducers';
import {
  initialUmsatzsteuerschluesselEntitiesState,
  umsatzsteuerschluesselEntitiesReducer
} from './umsatzsteuerschluessel-entities.reducers';
import {initialMengeneinheitEntitiesState, mengeneinheitEntitiesReducer} from './mengeneinheit-entities.reducers';
import {initialAppNavigationState} from '../../modules/pages/app-navigation/+store/app-navigation.state.initial';
import {appNavigationReducer} from '../../modules/pages/app-navigation/+store/app-navigation.reducer';
import {
  fakturierungsbelegContentReducer,
  initialFakturierungsbelegContentState
} from './fakturierungsbeleg-content.reducers';
import {einstellungenEntitiesReducer, initialEinstellungenEntitiesState} from './einstellungen-entities.reducers';
import {
  cancelFakturierungsbelegDialogReducer,
  initialCancelFakturierungsbelegDialogState
} from './cancel-fakturierungsbeleg-dialog.reducers';


export const initialAppState: AppState = {
  ...initialJfLibState,

  // INFO: Kundenbezogene Zustände
  kundeEntities: initialKundeEntitiesState,
  kundeLeistungsempfaenger: initialKundeLeistungsempfaengerState,
  kundenDialog: initialKundeDialogState,
  kundenTable: initialKundeTableState,
  deleteKundeDialog: initialDeleteKundeDialogState,

  // INFO: Produktbezogene Zustände
  produktEntities: initialProduktEntitiesState,
  produktDialog: initialProduktDialogState,
  produktTable: initialProduktTableState,
  deleteProduktDialog: initialDeleteProduktDialogState,

  // INFO: Fakturierungsbelegbezogene Zustände
  fakturierungsbelegEntities: initialFakturierungsbelegEntitiesState,
  fakturierungsbelegForm: initialFakturierungsbelegFormState,
  fakturierungsbelegTable: initialFakturierungsbelegTableState,
  fakturierungsbelegContent: initialFakturierungsbelegContentState,
  deleteFakturierungsbelegDialog: initialDeleteFakturierungsbelegDialogState,
  cancelFakturierungsbelegDialog: initialCancelFakturierungsbelegDialogState,

  // INFO: Weitere Dialogzustände
  belegeEmptyStateDialog: initialBelegeEmptyStateDialogState,
  uploadLogoDialog: initialUploadLogoDialogState,

  // INFO: Zusätzliche Zustände
  addPosition: initialAddPositionState,
  appNavigation: initialAppNavigationState,
  landEntities: initialLandEntitiesState,
  mengeneinheitEntities: initialMengeneinheitEntitiesState,
  umsatzsteuerschluesselEntities: initialUmsatzsteuerschluesselEntitiesState,
  einstellungenEntities: initialEinstellungenEntitiesState,
};

export const reducers: ActionReducerMap<AppState> = {
  ...jfLibReducers,

  // INFO: Kundenbezogene Reducer
  kundeEntities: kundeEntitiesReducer,
  kundeLeistungsempfaenger: kundeLeistungsempfaengerReducer,
  kundenDialog: kundeDialogReducer,
  kundenTable: kundeTableReducer,
  deleteKundeDialog: deleteAbsenderDialogReducer,

  // INFO: Produktbezogene Reducer
  produktEntities: produktEntitiesReducer,
  produktDialog: produktDialogReducer,
  produktTable: produktTableReducer,
  deleteProduktDialog: deleteProduktDialogReducer,

  // INFO: Fakturierungsbelegbezogene Reducer
  fakturierungsbelegEntities: fakturierungsbelegEntitiesReducer,
  fakturierungsbelegForm: fakturierungsbelegFormReducer,
  fakturierungsbelegTable: fakturierungsbelegTableReducer,
  fakturierungsbelegContent: fakturierungsbelegContentReducer,
  deleteFakturierungsbelegDialog: deleteFakturierungsbelegDialogReducer,
  cancelFakturierungsbelegDialog: cancelFakturierungsbelegDialogReducer,

  // INFO: Weitere Dialogreducer
  belegeEmptyStateDialog: belegeEmptyStateDialogReducer,
  uploadLogoDialog: uploadLogoDialogReducer,

  // INFO: Zusätzliche Reducer
  addPosition: addPositionReducer,
  appNavigation: appNavigationReducer,
  landEntities: landEntitiesReducer,
  mengeneinheitEntities: mengeneinheitEntitiesReducer,
  umsatzsteuerschluesselEntities: umsatzsteuerschluesselEntitiesReducer,
  einstellungenEntities: einstellungenEntitiesReducer,
};

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [logger] : [];
