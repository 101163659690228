import {createAction, props} from '@ngrx/store';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';
import {BelegDTO, KundeDTO, KundePageDTO, KundeRequestDTO, ProblemDTO} from '../../openapi/fakturierung-openapi';


export class KundeEntitiesActions {

  private static readonly prefix = '[Kunde Entities] ';

  public static getKundeById = createAction(
    this.prefix + 'Load kunde by ID.',
    props<{
      betriebId: string;
      kundeId: string;
    }>(),
  );

  public static loadKundeByIdIfAbsent = createAction(
    this.prefix + 'Load absender by ID if absent.',
    props<{
      betriebId: string;
      kundeId: string;
    }>(),
  );

  public static getKundeByIdSuccess = createAction(
    this.prefix + 'Load kunde successfully.',
    props<{
      kundeDto: KundeDTO;
    }>(),
  );

  public static getKundeByIdFailure = createAction(
    this.prefix + 'Load kunde failed.',
    props<{
      error: any;
    }>(),
  );

  public static readKundenSuccess = createAction(
    this.prefix + 'Read list of kunden successfully.',
    props<{
      kundeDtos: KundeDTO[];
    }>(),
  );

  public static readKundenFailed = createAction(
    this.prefix + 'Read list of kunden failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static countKundenElementsSuccess = createAction(
    this.prefix + 'Count list of kunden elements successfully.',
    props<{
      totalElements: number,
    }>(),
  );

  public static countKundenPagesSuccess = createAction(
    this.prefix + 'Count list of kunden pages successfully.',
    props<{
      totalPages: number,
    }>(),
  );

  public static createKunde = createAction(
    this.prefix + 'Create kunde.',
    props<{
      betriebId: string,
      requestDto: KundeRequestDTO,
      addToInvoice: boolean,
      beleg: BelegDTO | undefined,
    }>(),
  );

  public static createKundeSuccess = createAction(
    this.prefix + 'Created kunde successfully.',
    props<{ kundeDto: KundeDTO }>(),
  );

  public static createKundeFailure = createAction(
    this.prefix + 'Create kunde failed.',
    props<{ error: MappedHttpErrorResponse<ProblemDTO> }>(),
  );

  public static updateKunde = createAction(
    this.prefix + 'Update kunde.',
    props<{
      betriebId: string,
      kundeId: string,
      requestDto: KundeRequestDTO,
    }>(),
  );

  public static updateKundeSuccess = createAction(
    this.prefix + 'Updated kunde successfully.',
    props<{ kundeDto: KundeDTO }>(),
  );

  public static updateKundeFailure = createAction(
    this.prefix + 'Updated kunde failed.',
    props<{ error: MappedHttpErrorResponse<ProblemDTO> }>(),
  );

  public static deleteKunde = createAction(
    this.prefix + 'Delete kunde.',
    props<{
      betriebId: string,
      kundeId: string,
    }>(),
  );

  public static deleteKundeSuccess = createAction(
    this.prefix + 'Deleted kunde successfully.',
    props<{
      kundeId: string,
    }>(),
  );

  public static deleteKundeFailure = createAction(
    this.prefix + 'Delete kunde failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

  public static detectNextKundennummer = createAction(
    this.prefix + 'Detect next kundennummer.',
    props<{
      betriebId: string,
    }>(),
  );

  public static detectNextKundennummerSuccess = createAction(
    this.prefix + 'Detected next kundennummer successfully.',
    props<{
      betriebId: string,
      kunde: KundePageDTO,
    }>(),
  );

  public static detectNextKundennummerFailure = createAction(
    this.prefix + 'Detect next kundennummer failed.',
    props<{ error: MappedHttpErrorResponse }>(),
  );

}
