<jf-form-block *ngIf="formControls && anredeOptions && landOptions">
  <jf-form-field-select
    class="form-field-anrede"
    [label]="'Anrede'"
    [formControl]="formControls.controls.anrede"
    [options]="anredeOptions"
  ></jf-form-field-select>

  <jf-form-field-text
    class="form-field-titel"
    [formControl]="formControls.controls.titel"
    [label]="'Titel'"
  ></jf-form-field-text>

  <jf-form-field-number
    class="form-field-kundennummer"
    [message]="kundennummerValidatorMessage"
    [label]="'Kundennummer'"
    [formControl]="formControls.controls.kundennummer"
    [decimalPlaces]="0"
    [allowNegative]="false"
  ></jf-form-field-number>

  <jf-form-field-text
    class="form-field-vorname"
    [label]="'Vorname'"
    [formControl]="formControls.controls.vorname"
  ></jf-form-field-text>

  <jf-form-field-text
    class="form-field-nachname"
    [label]="'Nachname'"
    [formControl]="formControls.controls.nachname"
  ></jf-form-field-text>

  <jf-form-field-text
    class="form-field-strasse"
    [label]="'Straße + Hausnummer'"
    [formControl]="formControls.controls.strasseHausnummer"
  ></jf-form-field-text>

  <jf-form-field-text
    class="form-field-plz"
    [maxlength]="5"
    [label]="'PLZ'"
    [formControl]="formControls.controls.plz"
    [message]="plzValidatorMessage"
  ></jf-form-field-text>

  <jf-form-field-text
    class="form-field-ort"
    [label]="'Ort'"
    [message]="ortValidatorMessage"
    [formControl]="formControls.controls.ort"
  ></jf-form-field-text>

  <jf-form-field-select
    class="form-field-land"
    [label]="'Land'"
    [formControl]="formControls.controls.landPrivat"
    [options]="landOptions"
  ></jf-form-field-select>

  <jf-form-field-text
    class="form-field-email form-field-full-width"
    [label]="'E-Mail-Adresse'"
    [formControl]="formControls.controls.email"
    [message]="emailValidatorMessage"
  ></jf-form-field-text>
</jf-form-block>

