import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DeleteFakturierungsbelegDialogSelectors} from '../store/selectors/delete-fakturierungsbeleg-dialog.selectors';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {
  DeleteFakturierungsbelegDialogComponent
} from '../modules/dialogs/delete-fakturierungsbeleg-dialog/delete-fakturierungsbeleg-dialog.component';
import {EmptyBelegeDialogComponent} from '../modules/dialogs/empty-belege-dialog/empty-belege-dialog.component';
import {BelegeEmptyStateDialogSelectors} from '../store/selectors/belege-emptystate-dialog.selectors';
import {DeleteKundeDialogSelectors} from '../store/selectors/delete-kunde-dialog.selectors';
import {
  DeleteKundeDialogComponent
} from '../modules/dialogs/delete-kunde-dialog/delete-kunde-dialog.component';
import {DeleteProduktDialogComponent} from '../modules/dialogs/delete-produkt-dialog/delete-produkt-dialog.component';
import {DeleteProduktDialogSelectors} from '../store/selectors/delete-produkt-dialog.selectors';
import {UploadLogoDialogComponent} from '../modules/dialogs/upload-logo-dialog/upload-logo-dialog.component';
import {UploadLogoDialogSelectors} from '../store/selectors/upload-logo-dialog.selectors';
import {ProduktDialogSelectors} from '../store/selectors/produkt-dialog.selectors';
import {ProduktDialogComponent} from '../modules/dialogs/produkt-dialog/produkt-dialog.component';
import {
  KundeDialogComponent
} from '../modules/dialogs/kunde-dialog/kunde-dialog.component';
import {KundeDialogSelectors} from '../store/selectors/kunde-dialog.selectors';
import {CancelFakturierungsbelegDialogSelectors} from '../store/selectors/cancel-fakturierungsbeleg-dialog.selectors';
import {
  CancelFakturierungsbelegDialogComponent
} from '../modules/dialogs/cancel-fakturierungsbeleg-dialog/cancel-fakturierungsbeleg-dialog.component';


/**
 * @class UiService
 *
 * Der UiService ist zuständig für das Steuern globaler UI-Operationen
 * auf der ganzen App. Dieser Service wird von der AppComponent beim
 * Initialisieren geladen und ermöglicht es bestimmte UI-Aspekte
 * basierend auf dem Zustand des Redux-Stores zu steuern.
 *
 * U.a. steuert dieser Service das Öffnen eines Vorschau-Dialogs,
 * abhängig von dem Wert 'belegId' im Store. Hiermit wird vermieden,
 * dass die gleiche Funktionalität redundant in verschiedenen
 * Komponenten implementiert werden muss. Dies hat nicht nur den
 * Vorteil, den Code sauberer und wartbarer zu machen, sondern kann
 * auch Performanzproblemen vorbeugen.
 *
 * Ein weiterer Vorteil davon, dass diese Operationen durch den
 * UiService und nicht durch die Effekte gesteuert werden, besteht
 * darin, dass der UiService eher der "Ansichtslogik" entspricht,
 * und es somit intuitiver ist, ihn für die Ansichtssteuerung zu
 * verwenden, im Vergleich zur Verwendung der Effekte, die eher
 * für die 'Geschäftslogik' geeignet sind.
 *
 * Problematisch wäre es, wenn diese Logik in jeder einzelnen Komponenten
 * liegen würde, da jede dieser Implementierungen gewartet werden muss.
 * Darüber hinaus könnte sich die Implementierung leicht in kleinen
 * Detailpunkten unterscheiden, was zu Inkonsistenzen in der
 * Benutzererfahrung führen könnte.
 *
 * Auch das Preloading der Module weist den Vorteil auf, dass die
 * Module genau dann geladen werden, wenn sie benötigt werden. Dies
 * kann die Benutzererfahrung erheblich verbessern, insbesondere in
 * Anwendungen, die eine Vielzahl von Modulen verwenden.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class UiService {

  private readonly modules: {
    [key: string]: { modulePath: Promise<any>, moduleName: string, componentName: string }
  } = {
    deleteFakturierungsbelegDialog: {
      modulePath: import('../modules/dialogs/delete-fakturierungsbeleg-dialog/delete-fakturierungsbeleg-dialog.module'),
      moduleName: 'DeleteFakturierungsbelegDialogModule',
      componentName: 'deleteFakturierungsbelegDialogComponent',
    },
    cancelFakturierungsbelegDialog: {
      modulePath: import('../modules/dialogs/cancel-fakturierungsbeleg-dialog/cancel-fakturierungsbeleg-dialog.module'),
      moduleName: 'CancelFakturierungsbelegDialogModule',
      componentName: 'cancelFakturierungsbelegDialogComponent',
    },
    uploadLogoDialog: {
      modulePath: import('../modules/dialogs/upload-logo-dialog/upload-logo-dialog.module'),
      moduleName: 'UploadLogoDialogModule',
      componentName: 'uploadLogoDialogComponent',
    },
    deleteKundeDialog: {
      modulePath: import('../modules/dialogs/delete-kunde-dialog/delete-kunde-dialog.module'),
      moduleName: 'DeleteKundeDialogModule',
      componentName: 'deleteKundeDialogComponent',
    },
    deleteProduktDialog: {
      modulePath: import('../modules/dialogs/delete-produkt-dialog/delete-produkt-dialog.module'),
      moduleName: 'DeleteProduktDialogModule',
      componentName: 'deleteProduktDialogComponent',
    },
    emptyBelegeDialog: {
      modulePath: import('../modules/dialogs/empty-belege-dialog/empty-belege-dialog.module'),
      moduleName: 'EmptyBelegeDialogModule',
      componentName: 'emptyBelegeDialogComponent',
    },
    produktDialog: {
      modulePath: import('../modules/dialogs/produkt-dialog/produkt-dialog.module'),
      moduleName: 'ProduktDialogModule',
      componentName: 'produktDialog',
    },
    kundeDialog: {
      modulePath: import('../modules/dialogs/kunde-dialog/kunde-dialog.module'),
      moduleName: 'KundeDialogModule',
      componentName: 'kundeDialog',
    },
  };

  private deleteFakturierungsbelegDialog?: MatDialogRef<DeleteFakturierungsbelegDialogComponent, any>;
  private cancelFakturierungsbelegDialog?: MatDialogRef<CancelFakturierungsbelegDialogComponent, any>;
  private uploadLogoDialog?: MatDialogRef<UploadLogoDialogComponent, any>;
  private deleteKundeDialog?: MatDialogRef<DeleteKundeDialogComponent, any>;
  private deleteProduktDialog?: MatDialogRef<DeleteProduktDialogComponent, any>;
  private emptyBelegeDialog?: MatDialogRef<EmptyBelegeDialogComponent, any>;
  private produktDialog?: MatDialogRef<ProduktDialogComponent, any>;
  private kundeDialog?: MatDialogRef<KundeDialogComponent, any>;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
  ) {
    this.preloadModules();
    this.initDeleteFakturierungsbelegDialog();
    this.initCancelFakturierungsbelegDialog();
    this.initUploadLogoDialog();
    this.initDeleteKundeDialog();
    this.initDeleteProduktDialog();
    this.initBelegEmptyStateDialog();
    this.initProduktDialog();
    this.initKundeDialog();
  }

  /**
   * Lädt die hier verwendeten Module vor.
   * Diese Funktion wird beim Initialisieren des Services aufgerufen und
   * lädt die verwendeten Module vor (Preloading), unabhängig von der Routing-Konfiguration.
   *
   * Durch Verwendung von setTimeout mit einer Verzögerung von 0 wird sichergestellt,
   * dass das Vorladen des Moduls nicht den Initialisierungsprozess der Anwendung blockiert.
   * Stattdessen wird es so schnell wie möglich ausgeführt, nachdem der aktuelle Call Stack abgearbeitet ist.
   */
  private preloadModules(): void {
    Object.keys(this.modules).forEach(moduleKey => {
      setTimeout(() => {
        this.modules[moduleKey].modulePath.then(m => m[this.modules[moduleKey].moduleName]);
      }, 0);
    });
  }

  private initDeleteFakturierungsbelegDialog(): void {
    this.store.select(DeleteFakturierungsbelegDialogSelectors.isDeleteFakturierungsbelegDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.deleteFakturierungsbelegDialog.modulePath
          .then(m => m[this.modules.deleteFakturierungsbelegDialog.moduleName].components[this.modules.deleteFakturierungsbelegDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.deleteFakturierungsbelegDialog = this.dialog.open(DeleteFakturierungsbelegDialogComponent, {
            width: '530px',
            minWidth: '530px',
            autoFocus: false,
            disableClose: true,
            restoreFocus: false, // INFO: Focus wird nicht auf das letzte Element gesetzt, wenn der Dialog geschlossen wird um das Tooltip nicht erneut zu triggern.
          });
        } else {
          this.deleteFakturierungsbelegDialog?.close();
        }
      }
    );
  }


  private initCancelFakturierungsbelegDialog(): void {
    this.store.select(CancelFakturierungsbelegDialogSelectors.isCancelFakturierungsbelegDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.cancelFakturierungsbelegDialog.modulePath
          .then(m => m[this.modules.cancelFakturierungsbelegDialog.moduleName].components[this.modules.cancelFakturierungsbelegDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.cancelFakturierungsbelegDialog = this.dialog.open(CancelFakturierungsbelegDialogComponent, {
            width: '530px',
            minWidth: '530px',
            autoFocus: false,
            disableClose: true,
            restoreFocus: false, // INFO: Focus wird nicht auf das letzte Element gesetzt, wenn der Dialog geschlossen wird um das Tooltip nicht erneut zu triggern.
          });
        } else {
          this.cancelFakturierungsbelegDialog?.close();
        }
      }
    );
  }


  private initUploadLogoDialog(): void {
    this.store.select(UploadLogoDialogSelectors.isUploadLogoDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.uploadLogoDialog.modulePath
          .then(m => m[this.modules.uploadLogoDialog.moduleName].components[this.modules.uploadLogoDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.uploadLogoDialog = this.dialog.open(UploadLogoDialogComponent, {
            width: '530px',
            minWidth: '530px',
            autoFocus: false,
            disableClose: true,
            restoreFocus: false, // INFO: Focus wird nicht auf das letzte Element gesetzt, wenn der Dialog geschlossen wird um das Tooltip nicht erneut zu triggern.
          });
        } else {
          this.uploadLogoDialog?.close();
        }
      }
    );
  }


  private initDeleteKundeDialog(): void {
    this.store.select(DeleteKundeDialogSelectors.isDeleteKundeDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.deleteKundeDialog.modulePath
          .then(m => m[this.modules.deleteKundeDialog.moduleName].components[this.modules.deleteKundeDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.deleteKundeDialog = this.dialog.open(DeleteKundeDialogComponent, {
            width: '530px',
            minWidth: '530px',
            autoFocus: false,
            disableClose: true,
            restoreFocus: false, // INFO: Focus wird nicht auf das letzte Element gesetzt, wenn der Dialog geschlossen wird um das Tooltip nicht erneut zu triggern.
          });
        } else {
          this.deleteKundeDialog?.close();
        }
      }
    );
  }

  private initDeleteProduktDialog(): void {
    this.store.select(DeleteProduktDialogSelectors.isDeleteProduktDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.deleteProduktDialog.modulePath
          .then(m => m[this.modules.deleteProduktDialog.moduleName].components[this.modules.deleteProduktDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.deleteProduktDialog = this.dialog.open(DeleteProduktDialogComponent, {
            width: '530px',
            minWidth: '530px',
            autoFocus: false,
            disableClose: true,
            restoreFocus: false, // INFO: Focus wird nicht auf das letzte Element gesetzt, wenn der Dialog geschlossen wird um das Tooltip nicht erneut zu triggern.
          });
        } else {
          this.deleteProduktDialog?.close();
        }
      }
    );
  }

  private initBelegEmptyStateDialog(): void {
    this.store.select(BelegeEmptyStateDialogSelectors.isBelegeEmptyStateDialogOpen).pipe(
      switchMap(isOpen => {
        this.modules.emptyBelegeDialog.modulePath
          .then(m => m[this.modules.emptyBelegeDialog.moduleName].components[this.modules.emptyBelegeDialog.componentName]);
        return of(isOpen);
      })
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.emptyBelegeDialog = this.dialog.open(EmptyBelegeDialogComponent, {
            width: '540px',
            minWidth: '540px',
            autoFocus: false,
            disableClose: true,
          });
        } else {
          this.emptyBelegeDialog?.close();
        }
      }
    );
  }

  private initProduktDialog(): void {
    this.store.select(ProduktDialogSelectors.isOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.produktDialog.modulePath
          .then(m => m[this.modules.produktDialog.moduleName].components[this.modules.produktDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.produktDialog = this.dialog.open(ProduktDialogComponent, {
            minWidth: '533px',
            autoFocus: false,
            disableClose: true,
            restoreFocus: false, // INFO: Focus wird nicht auf das letzte Element gesetzt, wenn der Dialog geschlossen wird um das Tooltip nicht erneut zu triggern.
          });
        } else {
          this.produktDialog?.close();
        }
      }
    );
  }

  private initKundeDialog(): void {
    this.store.select(KundeDialogSelectors.isKundeDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.kundeDialog.modulePath
          .then(m => m[this.modules.kundeDialog.moduleName].components[this.modules.kundeDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.kundeDialog = this.dialog.open(KundeDialogComponent, {
            minWidth: '533px',
            width: '575px',
            minHeight: '583px',
            autoFocus: false,
            disableClose: true,
            restoreFocus: false, // INFO: Focus wird nicht auf das letzte Element gesetzt, wenn der Dialog geschlossen wird um das Tooltip nicht erneut zu triggern.
          });
        } else {
          this.kundeDialog?.close();
        }
      }
    );
  }

}
